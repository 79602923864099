import { useCallback, useEffect, useState } from "react";
import Typography from '@material-ui/core/Typography';
import { bankAccountFormatter, options as optionsConst } from "../../../../utils/AppConst";
import { membershipStatusLabel } from "../../../../utils/cellRenderer/MemberShipStatusCellRenderer";
import { semesterStatusLabel } from "../../../../utils/cellRenderer/SemesterStatusCellRenderer";
import { getUser } from "../../../../utils/User";
import Address from "../../../Common/Address";
import DatePicker from "../../../Common/DatePicker";
import Form from "../../../Common/Form";
import Select from "../../../Common/Select";
import TextField from "../../../Common/TextField";
import Title from "../../../Common/Title";
import Training from "../../../Common/Training";
import Universities from "./Universities";
import PhoneInputField from "../../../Common/PhoneInputField";
import { getCentralFields } from "./utils";
import API from "../../../../utils/API";
import Certificates from "./Certificates";

export default function FormCore({
    formProps,
    form,
    campuses,
    universities,
    edit,
}) {
    const user = getUser();
    const [centralFields, setCentralFields] = useState([]);
    const [nationalities, setNationalities] = useState([]);

    useEffect(() => {
        getNationalities();
    }, []);

    function getNationalities() {
        API.get(`/enrollments/nationalities`)
            .then((data) => {
                setNationalities(data);
            })
            .catch((error) => {
                console.error("Error fetching nationalities:", error);
            });
    }
    
    useEffect(() => {
        const fetchCentralFields = async () => {
            try {
                const response = await getCentralFields();
                setCentralFields(response.data);
            } catch (error) {
                console.error("Error fetching central fields:", error);
            }
        };

        fetchCentralFields();
    }, []);

    useEffect(() => {
        if (form.membershipStatus === "external") {
            formProps.changeform(
                { ...form, building: null, floor: null, room: null },
                "form"
            );
        }
    }, [form.membershipStatus]);

    // Hákolás rovatunk első része:
    useEffect(() => {
        if (typeof form.university === "object") {
            formProps.changeform(
                { ...form, university: form.university?.id },
                "form"
            );
        }
    }, [form.university, universities]);

    // Hákolás rovatunk második része:
    useEffect(() => {
        if (!form.campus) {
            formProps.changeform({ ...form, campus: user?.campus }, "form");
        }
    }, [form.campus]);

    const bankAccountNumberFormatter = useCallback(
        (onChange) => (event) => {
            bankAccountFormatter(onChange, event);
        },
        []
    );

    const shouldDisplayField = (fieldName) => {
        return !centralFields.includes(fieldName);
    };

    /**
     * Jóváhagyás űrlap (szerkesztés and such):
     */
    return (
        <>
            <Title title="Típus" {...formProps} format={{ xs: 12 }}>
                <TextField
                    name="type"
                    value={
                        form["type"] === "enrollment"
                            ? "Beiratkozás"
                            : "Kiiratkozás" || ""
                    }
                    format={{ xs: 12 }}
                    disabled
                />
            </Title>
            <Title title="Név" {...formProps} format={{ xs: 12 }}>
                <TextField
                    label="Vezetéknév"
                    name="lastName"
                    value={form.lastName || ""}
                    format={{ xs: 12, md: 6 }}
                    error={form.errors?.lastName}
                    helperText={form.errors?.lastName}
                />
                <TextField
                    label="Keresztnév"
                    name="firstName"
                    value={form.firstName || ""}
                    format={{ xs: 12, md: 6 }}
                    error={form.errors?.firstName}
                    helperText={form.errors?.firstName}
                />
            </Title>
            <Title title="Képzés" {...formProps} format={{ xs: 12 }}>
                {!edit ? (
                    <Form
                        childComponent="true"
                        {...formProps}
                        format={{ xs: 12 }}
                    >
                        <TextField
                            label="Képzési szint"
                            name="trainingLevel"
                            value={form.trainingLevel?.name || ""}
                            format={{ xs: 12, md: 4 }}
                            disabled
                        />
                        <TextField
                            label="Képzési program"
                            name="trainingProgram"
                            value={form.trainingProgram?.name || ""}
                            format={{ xs: 12, md: 4 }}
                            disabled
                        />
                        <TextField
                            label="Évfolyam"
                            name="collegeYear"
                            value={form.collegeYear?.name || ""}
                            format={{ xs: 12, md: 4 }}
                            disabled
                        />
                    </Form>
                ) : (
                    <Training format={{ xs: 12 }} formProps={formProps} />
                )}
                {edit ? (
                    <Select
                        value={form.membershipStatus}
                        selectLabel="Státusz"
                        optionList={optionsConst.statusOptions}
                        name="membershipStatus"
                        format={{ xs: 12 }}
                        error={form.errors?.membershipStatus}
                        helperText={form.errors?.membershipStatus}
                    />
                ) : (
                    <TextField
                        label="Státusz"
                        name="membershipStatus"
                        value={membershipStatusLabel(form.membershipStatus)}
                        format={{ xs: 12 }}
                        disabled
                    />
                )}
                {edit ? (
                    <Select
                        value={form.currentMembershipStatus}
                        selectLabel="Aktuális státusz"
                        optionList={optionsConst.statusOptions}
                        name="currentMembershipStatus"
                        format={{ xs: 12 }}
                        error={form.errors?.currentMembershipStatus}
                        helperText={form.errors?.currentMembershipStatus}
                    />
                ) : (
                    <TextField
                        label="Aktuális státusz"
                        name="currentMembershipStatus"
                        value={membershipStatusLabel(
                            form.currentMembershipStatus
                        )}
                        format={{ xs: 12 }}
                        disabled
                    />
                )}
                {edit ? (
                    <Select
                        value={form.semesterStatus}
                        selectLabel="Szemeszter státusz"
                        optionList={optionsConst.semesterStatusOptions}
                        name="semesterStatus"
                        format={{ xs: 12 }}
                        error={form.errors?.semesterStatus}
                        helperText={form.errors?.semesterStatus}
                    />
                ) : (
                    <TextField
                        label="Szemeszter státusz"
                        name="semesterStatus"
                        value={semesterStatusLabel(form.semesterStatus)}
                        format={{ xs: 12 }}
                        disabled
                    />
                )}
            </Title>
            {shouldDisplayField("omId") && (
                <Title title="Oktatási azonosító" {...formProps} format={{ xs: 12 }}>
                    <TextField
                        name="omId"
                        value={form.omId || ""}
                        format={{ xs: 12 }}
                        error={form.errors?.omId}
                        helperText={
                            form.errors?.omId || (edit && "pl.: 71234567891")
                        }
                    />
                </Title>
            )}
            {shouldDisplayField("taxId") && (
                <Title title="Adóazonosító jel" {...formProps} format={{ xs: 12 }}>
                    <TextField
                        name="taxId"
                        value={form.taxId || ""}
                        format={{ xs: 12 }}
                        error={form.errors?.taxId}
                        helperText={
                            form.errors?.taxId || (edit && "pl.: 8412731018")
                        }
                    />
                </Title>
            )}
            {shouldDisplayField("taj") && (
                <Title title="TAJ szám" {...formProps} format={{ xs: 12 }}>
                    <TextField
                        name="taj"
                        value={form.taj || ""}
                        format={{ xs: 12 }}
                        error={form.errors?.taj}
                        helperText={
                            form.errors?.taj
                        }
                    />
                </Title>
            )}
            <Title title="Campus" {...formProps} format={{ xs: 12 }}>
                <TextField
                    name="campus"
                    value={
                        form.campus?.name ||
                        campuses["hydra:member"].find(
                            (x) => x["@id"] === form.campus
                        )?.name ||
                        ""
                    }
                    format={{ xs: 12 }}
                    disabled
                />
            </Title>
            <Address
                title="Állandó lakcím"
                detailedAddress
                {...formProps}
                format={{ xs: 12 }}
            />
            {shouldDisplayField("motherLastName" || "motherFirstName") && (
                <Title title="Anyja neve" {...formProps} format={{ xs: 12 }}>
                    <TextField
                        label="Vezetéknév"
                        name="motherLastName"
                        value={form.motherLastName || ""}
                        format={{ xs: 12, md: 6 }}
                        error={form.errors?.motherLastName}
                        helperText={form.errors?.motherLastName}
                    />
                    <TextField
                        label="Keresztnév"
                        name="motherFirstName"
                        value={form.motherFirstName || ""}
                        format={{ xs: 12, md: 6 }}
                        error={form.errors?.motherFirstName}
                        helperText={form.errors?.motherFirstName}
                    />
                </Title>
            )}
            {shouldDisplayField("birthPlace" || "birthDate") && (
                <Title
                    title="Születési hely/idő"
                    {...formProps}
                    format={{ xs: 12 }}
                >
                    <TextField
                        label="Születési hely"
                        name="birthPlace"
                        value={form.birthPlace || ""}
                        format={{ xs: 12, md: 6 }}
                        error={form.errors?.birthPlace}
                        helperText={form.errors?.birthPlace}
                    />
                    <DatePicker
                        label="Születési idő"
                        name="birthDate"
                        value={form.birthDate || ""}
                        format={{ xs: 12, md: 6 }}
                        error={form.errors?.birthDate}
                        helperText={form.errors?.birthDate}
                    />
                </Title>
            )}

            {shouldDisplayField("nationality") && (
                <Title title="Állampolgárság" {...formProps} format={{ xs: 12 }}>
                    <Select
                        value={form.nationality || []}
                        selectLabel="Állampolgárság"
                        optionList={nationalities.data || []}
                        name="nationality"
                        format={{ xs: 12 }}
                        multiple
                        error={form.errors?.nationality}
                        helperText={form.errors?.nationality}
                    />
                </Title>
            )}
            {shouldDisplayField("idCardNumber") && (
                <Title title="SZIG szám" {...formProps} format={{ xs: 12 }}>
                    <TextField
                        name="idCardNumber"
                        value={form.idCardNumber || ""}
                        format={{ xs: 12 }}
                        error={form.errors?.idCardNumber}
                        helperText={
                            form.errors?.idCardNumber ||
                            (edit && "pl.: 346243XX")
                        }
                    />
                </Title>
            )}
            {shouldDisplayField("kepIdCardNumber") && (
                <Title title="SZIG szám - KEP" {...formProps} format={{ xs: 12 }}>
                    <TextField
                        name="kepIdCardNumber"
                        value={form.kepIdCardNumber || ""}
                        format={{ xs: 12 }}
                        error={form.errors?.kepIdCardNumber}
                        helperText={
                            form.errors?.kepIdCardNumber ||
                            (edit && "pl.: XX432612")
                        }
                    />
                </Title>
            )}
            {shouldDisplayField("identificationNumberCnp") && (
                <Title title="Személyi szám (CNP)" {...formProps} format={{ xs: 12 }}>
                    <TextField
                        name="identificationNumberCnp"
                        value={form.identificationNumberCnp || ""}
                        format={{ xs: 12 }}
                        error={form.errors?.identificationNumberCnp}
                        helperText={
                            form.errors?.identificationNumberCnp || (edit && "pl.: 8203114106118")
                        }
                        InputProps={ {InputProps: { maxLength: 13 } }}
                    />
                </Title>
            )}
            {shouldDisplayField("bankName") && (
                <Title title="Pénzintézet neve" {...formProps} format={{ xs: 12 }}>
                    <TextField
                        name="bankName"
                        value={form.bankName || ""}
                        format={{ xs: 12 }}
                        error={form.errors?.bankName}
                        helperText={form.errors?.bankName}
                    />
                </Title>
            )}
            {shouldDisplayField("bankAccountNumber") && (
                <Title title="Bankszámlaszám" {...formProps} format={{ xs: 12 }}>
                    <TextField
                        name="bankAccountNumber"
                        value={form.bankAccountNumber || ""}
                        format={{ xs: 12 }}
                        error={form.errors?.bankAccountNumber}
                        helperText={form.errors?.bankAccountNumber}
                        onChange={bankAccountNumberFormatter((value) => {
                            formProps.changeform(
                                { ...form, bankAccountNumber: value },
                                "form"
                            );
                        })}
                    />
                </Title>
            )}
            <Title title="Telefonszám" {...formProps} format={{ xs: 12 }}>
                <PhoneInputField
                    name="phoneNumber"
                    value={form.phoneNumber || ""}
                    format={{ xs: 12 }}
                    error={form.errors?.phoneNumber}
                    helperText={
                        form.errors?.phoneNumber ||
                        (edit && "pl.: +36201234567")
                    }
                    style={{marginTop: '0px'}}
                />
                {form.errors?.phoneNumber || edit && 
                    (<Typography style={{
                        color: 'rgba(0, 0, 0, 0.54)',
                        fontSize: '0.75rem',
                        fontWeight: 400,
                        marginLeft: '14px',
                        marginTop: '0px',
                    }}>
                        pl.: +36201234567
                    </Typography>)
                }
            </Title>
            <Title title="E-mail cím" {...formProps} format={{ xs: 12 }}>
                <TextField
                    name="email"
                    value={form.email || ""}
                    format={{ xs: 12 }}
                    disabled
                />
            </Title>
            <Universities/>
            <Certificates/>
        </>
    );
}
