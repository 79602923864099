import { Grid, IconButton } from '@material-ui/core';
import { useState } from 'react';
import { connect } from 'react-redux';
import enrollmentActions from '../../../../store/processes/newProcess/enrollment/actions';
import RawTitle from '../../../Common/RawTitle';
import StyledButton from '../../../Common/SubmitButton';
import SmallTable from '../../../Common/SmallTable';
import { Delete, Edit } from '@material-ui/icons';
import Dialog from '../../../Common/Dialog';
import { Skeleton } from '@material-ui/lab';
import instance from '../../../../utils/API';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadFile } from './utils';
import CertificateDetails from './CertificateDetails';
import { getTrainingFormLabelByValue } from '../../../../utils/AppConst';

const columns = () => [
    {
        value: 'institute',
        label: 'Intézmény',
    },
    { value: 'major', label: 'Szak' },
    {
        value: 'trainingType',
        label: 'Képzés formája',
        transformer: getTrainingFormLabelByValue
    },
    { value: 'operations', label: 'Műveletek' },
];

function Certificates(props) {
    const readonly = props.readonly;
    const notitle = props.notitle;
    const [loading, setLoading] = useState(false);
    const { form } = props;
    const [certificateForm, setCertificateForm] = useState(null);

    const handleDownload = (studentCertificate) => {
        instance.get(`/enrollments/${form.id}/fill-student/certificate/${studentCertificate.id}/download-file`, { responseType: 'blob' })
            .then((response) => {
                downloadFile(response.data, studentCertificate.certificateFileName);
            });
    };

    const certificates = (form.studentCertificate || []).map((certificate) => ({
        ...certificate,
        operations: (
            <>
                {!readonly && (
                    <>
                        <IconButton
                            color='primary'
                            onClick={() => setCertificateForm(certificate)}
                        >
                            <Edit />
                        </IconButton>
                        <Dialog
                            title='Megerősítés'
                            opener={
                                <IconButton color='secondary'>
                                    <Delete />
                                </IconButton>
                            }
                            action={
                                <StyledButton
                                    onClick={() => {
                                        setLoading(true);
                                        instance.delete(
                                            `/enrollments/${form.id}/fill-student/certificate/${certificate.id}`
                                        ).then(() => {
                                            props.getStudentCertificate(form.id).then(() => {
                                                setCertificateForm(null);
                                                setLoading(false);
                                            });
                                        })
                                        .catch(() => {
                                            setLoading(false);
                                        });
                                    }}
                                >
                                    Törlés
                                </StyledButton>
                            }
                        >
                            Biztosan törölni szeretnéd ezt a végzettséget?
                        </Dialog>
                    </>
                )}
                {certificate.certificateFileName && certificate.certificateFilePath && (
                    <IconButton color='default' onClick={() => handleDownload(certificate)}>
                        <GetAppIcon />
                    </IconButton>
                )}
            </>
        ),
    }));

    if (loading) {
        return <Skeleton />;
    }

    let cols = columns();

    return (
        <Grid item xs={12} container spacing={1} style={{ marginBottom: '8px' }}>
            {!notitle && (<RawTitle title='Végzettségek' />)}
            {!readonly && (
                <Grid item xs={12} style={{ textAlign: 'right' }}>
                        <span>
                            <StyledButton 
                                onClick={() => setCertificateForm({})}
                                disabled={certificates.length >= 5}
                            >
                                Végzettség hozzáadása
                            </StyledButton>
                        </span>
                </Grid>
            )}
            <Grid item xs={12}>
                <SmallTable columns={cols} rows={certificates} />
            </Grid>
            <CertificateDetails
                certificateForm={certificateForm}
                setCertificateForm={setCertificateForm}
            />
        </Grid>
    );
}

function mapState(state) {
    const { form } = state.enrollment;
    return { form };
}

const actionCreators = {
    changeForm: enrollmentActions.changeForm,
    getStudentCertificate: enrollmentActions.getStudentCertificate
};

export default connect(mapState, actionCreators)(Certificates);
