import { faPen, faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles, Typography } from "@material-ui/core";
import React, { Children, useEffect, useRef, useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import * as yup from "yup";
import colors from "../../styles/colors";
import { formatBankAccountNumberForDisplay, getDocumentField, getValue } from "../../utils/AppConst";
import { fieldsForForms } from "../../utils/fieldsForForms";
import userService from "./../../services/login/index";
import Enrollment from "./../Administration/Forms/Enrollment/index";
import CancelButton from "./CancelButton";
import Dialog from "./Dialog";
import Form from "./Form";
import FormPageTemplate from "./FormPageTemplate";
import FormToPrint from "./FormToPrint";
import SubmitButton from "./SubmitButton";
import TextField from "./TextField";
import Universities from "../Administration/Forms/Enrollment/Universities";
import API from "../../utils/API";
import Select from "./Select";
import Certificates from "../Administration/Forms/Enrollment/Certificates";

const useStyles = makeStyles(() => ({
    checkIcon: {
        marginRight: "4px",
    },
    dialogTitleRow: {
        fontWeight: "bold",
        paddingLeft: "10px",
        paddingleft: "10px",
    },
    dialogBodyText: {
        padding: "10px",
    },
}));

export default function TemplateForm(props) {
    const { header, formProps, campuses, universities, id } = props;
    const { form, changeform } = formProps;
    const [isEditing, setIsEditing] = useState(false);
    const [temporaryForm, setTemporaryForm] = useState(form);
    const ref = useRef();
    const classes = useStyles();

    const [nationalities, setNationalities] = useState([]);

    useEffect(() => {
        getNationalities();
    }, []);

    function getNationalities() {
        API.get(`/enrollments/nationalities`)
            .then((data) => {
                setNationalities(data);
            })
            .catch((error) => {
                console.error("Error fetching nationalities:", error);
            });
    }

    const handlePrint = useReactToPrint({
        content: () => ref.current,
    });
    const handleDiscardClick = () => {
        changeform(temporaryForm, "form");
        setIsEditing(false);
        props.setIsEdited(false);
    };

    const handleEditClick = () => {
        if (typeof form.university === "object") {
            changeform({ ...form, university: form.university?.id }, "form");
        }
        if (form.reason) {
            setIsEditing(true);
            setTemporaryForm(form);
            props.setIsEdited(true);
        }
    };

    const handleSaveClick = () => {
        let campusToFind = campuses["hydra:member"]?.find(
            (x) => x["@id"] === form.campus
        );
        let universityToFind = universities["hydra:member"]?.find(
            (x) => x.id === form.university
        );

        changeform(
            {
                ...form,
                campus: campusToFind,
                university: universityToFind,
            },
            "form"
        );

        props.loadingOn();
        props
            .saveEnrollment(form, id)
            .then(() => props.getEnrollment(id))
            .then(() => {
                setIsEditing(false);
                props.loadingOff();
            })
            .catch((error) => {
                const violations = error.response?.data?.violations;

                let errors = {};
                violations?.forEach((violation) => {
                    errors[violation.propertyPath] = violation.message;
                });

                changeform({ ...form, errors: errors }, "form");

                props.loadingOff();
            });
    };

    const getField = (field) => {
        const value = getValue(field, form);

        if (field.value === 'bankAccountNumber') {
            form['bankAccountNumber'] = formatBankAccountNumberForDisplay(form['bankAccountNumber']);
        }

        if (field.value === "acceptedDocuments") {
            return form["acceptedDocuments"]?.map((f, i) => {
                return (
                    <TextField
                        label={i === 0 ? field.label : ""}
                        value={getDocumentField(f, form) || ""}
                        name={field.value}
                        disabled={!isEditing}
                        format={{ xs: 12 }}
                    />
                );
            });
        }

        if (form.status === "filling_approval" && !isEditing) {
            if (
                field.value === "membershipStatus" &&
                form.membershipStatus !== form.studentMembership
            ) {
                return (
                    <TextField
                        label={field.label}
                        value={getValue(field, form) || ""}
                        name={field.value}
                        disabled={field.disabled || !isEditing}
                        format={{ xs: 12 }}
                        helperText={`Figyelem: a folyamat státusza eltér a hallgató státuszától!`}
                        InputProps={{
                            style: { backgroundColor: colors.primaryYellow },
                        }}
                    />
                );
            }
            if (
                field.value === "currentMembershipStatus" &&
                form.currentMembershipStatus !==
                    form.studentCurrentMembership
            ) {
                return (
                    <TextField
                        label={field.label}
                        value={getValue(field, form) || ""}
                        name={field.value}
                        disabled={field.disabled || !isEditing}
                        format={{ xs: 12 }}
                        helperText={`Figyelem: a folyamat aktuális státusza eltér a hallgató aktuális státuszától!`}
                        InputProps={{
                            style: { backgroundColor: colors.primaryYellow },
                        }}
                    />
                );
            }
            if (
                field.value === "semesterStatus" &&
                form.semesterStatus !== form.studentSemesterStatus
            ) {
                return (
                    <TextField
                        label={field.label}
                        value={getValue(field, form) || ""}
                        name={field.value}
                        disabled={field.disabled || !isEditing}
                        format={{ xs: 12 }}
                        helperText={`Figyelem: a folyamat szemeszter státusza eltér a hallgató szemeszter státuszától!`}
                        InputProps={{
                            style: { backgroundColor: colors.primaryYellow },
                        }}
                    />
                );
            }
            if (field.value === "university") {
                return (<Universities readonly={true} notitle={true} format={{xs:12}}/>)
            }
        }

        if (value !== undefined) {
            if (field.value === "nationality") {
                return (
                    <Select
                        value={form.nationality || []}
                        selectLabel="Állampolgárság"
                        optionList={nationalities.data || []}
                        name="nationality"
                        format={{ xs: 12 }}
                        multiple
                        disabled={field.disabled || !isEditing}
                        error={form.errors?.nationality}
                        helperText={form.errors?.nationality}
                    />
                );
            } else {
                return (
                    <TextField
                        label={field.label}
                        value={value}
                        name={field.value}
                        disabled={field.disabled || !isEditing}
                        format={{ xs: 12 }}
                    />
                );
            }
        }
    
        return null;
    };

    const foreignUniversityCondition =
        form.university === 1326 ||
        form.university?.id === 1326 ||
        form.university?.name === "Külföldi képzés";

    return (
        <>
            <div style={{ display: "none" }}>
                <FormToPrint
                    ref={ref}
                    formProps={formProps}
                    header={header}
                    form={{
                        ...form,
                        city:
                            userService?.getUserObject()?.campus?.address
                                ?.city || "Budapest",
                    }}
                    getField={(field) => getValue(field, form)}
                />
            </div>
            <FormPageTemplate
                header={header}
                form={formProps}
                onEditClick={setIsEditing}
                xs={12}
                sm={12}
                md={12}
                lg={12}
            >
                {isEditing ? (
                    <Enrollment
                        formProps={formProps}
                        editForm={form}
                        edit={true}
                        campuses={campuses}
                    />
                ) : (
                    Children.toArray(
                        fieldsForForms(foreignUniversityCondition, form)?.map(
                            getField
                        )
                    )
                )}
                {!isEditing && <Universities readonly format={{xs: 12}}/>}
                {!isEditing && <Certificates readonly format={{xs: 12}}/>}
                {isEditing ? (
                    <>
                        <Dialog
                            opener={
                                <SubmitButton
                                    style={{ marginRight: "8px" }}
                                    disabled={
                                        !form.trainingProgram ||
                                        !form.collegeYear
                                    }
                                >
                                    Mentés
                                </SubmitButton>
                            }
                            title={"Hallgató beiratkozási adatainak módosítása"}
                            action={
                                <SubmitButton onClick={handleSaveClick}>
                                    Tovább
                                </SubmitButton>
                            }
                            cancelText="Mégse"
                        >
                            <Typography className={classes.dialogTitleRow}>
                                FIGYELEM!
                            </Typography>
                            <Typography className={classes.dialogBodyText}>
                                A következő funkcióval a hallgató beiratkozási
                                adatait módosíthatja. A módosításokat csak
                                többszöri ellenőrzés után érvényesítse.
                            </Typography>
                        </Dialog>

                        <CancelButton onClick={handleDiscardClick}>
                            Elvetés
                        </CancelButton>
                    </>
                ) : (
                    props.children
                )}
                {!isEditing && !props.disableEdit && (
                    <>
                        <ReactToPrint
                            trigger={() => (
                                <SubmitButton
                                    onClick={handlePrint}
                                    style={{ marginRight: "8px" }}
                                >
                                    <FontAwesomeIcon
                                        icon={faPrint}
                                        style={{ marginRight: "4px" }}
                                    />
                                    Nyomtatás
                                </SubmitButton>
                            )}
                            content={() => ref.current}
                            documentTitle={`beiratkozas_${
                                new Date().toISOString().split("T")[0]
                            }`}
                        />
                        <Dialog
                            opener={
                                <SubmitButton>
                                    <FontAwesomeIcon
                                        icon={faPen}
                                        style={{ marginRight: "4px" }}
                                    />
                                    Szerkesztés
                                </SubmitButton>
                            }
                            title="Javítás oka"
                            action={
                                <SubmitButton
                                    onClick={handleEditClick}
                                    disabled={!form.reason}
                                >
                                    Küldés
                                </SubmitButton>
                            }
                            format={{ xs: 12 }}
                        >
                            <Form
                                childComponent="true"
                                isformikform={true}
                                initialerrors={{}}
                                initialvalues={form}
                                validationSchema={yup.object().shape({
                                    reason: yup
                                        .string()
                                        .max(128, "Maximum 128 karakter lehet")
                                        .required("Kötelező megadni"),
                                })}
                                {...formProps}
                            >
                                <TextField
                                    label="Ok"
                                    onChange={(target) => {
                                        changeform({ ...form, reason: target.target.value }, "form");
                                    }}
                                    value={form.reason}
                                    name="reason"
                                    multiple
                                    format={{ xs: 12 }}
                                />
                            </Form>
                        </Dialog>
                    </>
                )}
            </FormPageTemplate>
        </>
    );
}
