import {
    faBook,
    faBookOpen,
    faBuilding,
    faCalendarAlt,
    faEnvelope,
    faGlobe,
    faGraduationCap,
    faList,
    faLock,
    faPlus,
    faServer,
    faSpinner,
    faSync,
    faUniversity,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import { getFinancingFormLabelByValue } from "../Components/Administration/Forms/Enrollment/options";
import { membershipStatusLabel } from "../utils/cellRenderer/MemberShipStatusCellRenderer";
import { semesterStatusLabel } from "./cellRenderer/SemesterStatusCellRenderer";
import { hasRole } from "./User";

export const REFRESH_INTERVAL_TIME = 60 * 1000; //percenként
export const DO_REFRESH_INTERVAL = 30 * 60 * 1000; //fél óránként

export const PERIODS_OF_VALIDITIES = {
    //milliszekundum
    accessToken: 60 * 60 * 1000,
};

export const LANGUAGES = [
    { value: "hu", label: "Magyar" },
    { value: "en", label: "Angol" },
];

const programOptions = [
    { value: "juniorTraining", label: "Juniorképzés" },
    { value: "leadershipTraining", label: "Vezetőképző akadémia" },
];

const semesterOptions = [
    { value: 3, name: "2021 ősz" },
    { value: 4, name: "2022 tavasz" },
];

const trainingOptions = [
    { value: "law", label: "Jog" },
    { value: "economics", label: "Közgazdaságtan" },
    { value: "internationalRelations", label: "Nemzetközi kapcsolatok" },
    { value: "centralEurope", label: "Közép-Európa" },
    { value: "media", label: "Média" },
    { value: "psychology", label: "Pszichológia" },
];

const statusOptions = [
    { value: "internal", label: "Bentlakó" },
    { value: "external", label: "Külsős" },
];

export const MEMBERSHIP_STATUS_OPTIONS = [
    { value: "internal", label: "Bentlakó" },
    { value: "external", label: "Külsős" },
];

export const getCurrentMembershipStatus = (membershipStatus) => {
    switch (membershipStatus) {
        case "internal":
            return "Bentlakó";
        case "external":
            return "Külsős";
        default:
            return "";
    }
};

export const rolesOptions = [
    { value: "ROLE_SYSTEM_ADMIN", label: "Rendszer admin", selectable: true},
    { value: "ROLE_UNIVERSITY_ADMIN", label: "Egyetemi admin", selectable: true },
    { value: "ROLE_FINANCIAL_ADMIN", label: "Pénzügyi admin", selectable: true},
    { value: "ROLE_OPERATOR_ADMIN", label: "Üzemeltetői admin", selectable: true },
    { value: "ROLE_STUDENT", label: "Hallgató", selectable: false},
    { value: "ROLE_USER", label: "Felhasználó", selectable: false },
];

export const itemStatus = [
    { value: "new", label: "Új" },
    { value: "excellent", label: "Kitűnő" },
    { value: "normal", label: "Normál" },
    { value: "deficient", label: "Hiányos" },
    { value: "injured", label: "Sérült" },
    { value: "unknown", label: "Ismeretlen" },
];

const trainingFormOptions = [
    { value: "ba", label: "BA" },
    { value: "bsc", label: "BSc" },
    { value: "ma", label: "MA" },
    { value: "msc", label: "MSc" },
    { value: "undivided", label: "Osztatlan" },
    { value: "other", label: "Egyéb" },
];

export const getTrainingFormLabelByValue = (value) =>
    trainingFormOptions.find((option) => option.value === value)?.label || value;

const financingFormOptions = [
    { value: "state_subsidized", label: "Államilag támogatott" },
    { value: "cost", label: "Önköltséges" },
];

const studentStatusOptions = [
    { value: "active", label: "Aktív" },
    { value: "passive", label: "Passzív" },
];

const genderOptions = [
    { value: "male", label: "Férfi" },
    { value: "female", label: "Nő" },
    { value: "unknown", label: "Ismeretlen" },
];

export const getStudentStatusLabelByValue = (value) =>
    studentStatusOptions.find((option) => option.value === value)?.label || "";

const semesterStatusOptions = [
    { value: "active", label: "Aktív" },
    { value: "passive", label: "Passzív" },
    { value: "foreign_scholarship", label: "Külföldi ösztöndíjas" },
];

export const getSemesterStatusOptionsLabelByValue = (value) =>
    studentStatusOptions.find((option) => option.value === value)?.label || "";

export const processStatusOptions = [
    { value: "student_filling", label: "Beiratkozás" },
    { value: "financial_check", label: "Pénzügyi ellenőrzés" },
    { value: "filling_approval", label: "Jóváhagyás" },
    { value: "room_take_over", label: "Szoba átvételi jegyzőkönyv" },
    { value: "operator_check", label: "Üzemeltetési jegyzőkönyv" },
    { value: "room_approval", label: "Szobajóváhagyás" },
    { value: "done", label: "Lezárult" },
];

export const processStatusOptionsTermination = [
    { value: "student_declaration", label: "Hallgatói nyilatkozat" },
    { value: "financial_check", label: "Pénzügyi ellenőrzés" },
    { value: "operator_check", label: "Üzemeltetési ellenőrzés" },
    { value: "done", label: "Lezárult" },
];

export const processStatusOptionsRelocation = [
    { value: "student_filling", label: "Hallgató tölti ki" },
    { value: "financial_check_relocation", label: "Pénzügyi ellenőrzés" },
    { value: "move_out_form", label: "Kiköltözési nyilatkozat" },
    { value: "operator_room_take_over", label: "Üzemeltetési szobaátvétel" },
    { value: "student_room_take_over", label: "Szobaátvételi nyilatkozat kitöltése" },
    { value: "approve_room_take_over", label: "Üzemeltetői ellenőrzés" },
    { value: "done", label: "Lezárult" },
];

const yesNoOptions = [
    { value: "1", label: "Igen" },
    { value: "0", label: "Nem" },
];

export const options = {
    genderOptions,
    statusOptions,
    programOptions,
    trainingOptions,
    trainingFormOptions,
    financingFormOptions,
    studentStatusOptions,
    semesterStatusOptions,
    semesterOptions,
    processStatusOptions,
    processStatusOptionsTermination,
    processStatusOptionsRelocation,
    yesNoOptions,
};

export const CAMPUSES = [
    { value: 1, label: "c1" },
    { value: 2, label: "c2" },
    { value: 3, label: "c3" },
    { value: 4, label: "c4" },
    { value: 5, label: "c5" },
    { value: 6, label: "c6" },
];

export const YEAR_OPTIONS = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
];

export const EMAIL_TEMPLATES = {
    enrollment: {
        emailSubject: "Beiratkozás",
        emailContent:
            "Kedves %NÉV%!\nA beiratkozással kapcsolatos információkért keresse munkatársainkat!",
    },
};

export const COUNTRIES = [
    { value: "Ausztria", label: "Ausztria" },
    { value: "Magyarország", label: "Magyarország" },
    { value: "Románia", label: "Románia" },
    { value: "Szerbia", label: "Szerbia" },
];

export const PROCESS_TYPES = [
    { value: "enrollment", label: "Beiratkozás" },
    { value: "student_allocation_preference", label: "Szobatárspreferencia" },
    { value: "relocation", label: "Átköltözés" },
    { value: "termination", label: "Kiiratkozás" },
    { value: "move_out", label: "Kiköltözés" },
];

export const PROCESS_TYPE_NAMES = {
    ENROLLMENT: "enrollment",
    RELOCATION: "relocation",
    STUDENT_ALLOCATION_PREFERENCE: "student_allocation_preference",
    TERMINATION: "termination",
    MOVE_OUT: "move_out",
};

export const getProcessTypeLabel = (value) =>
    PROCESS_TYPES.find((type) => type.value === value)?.label || "";

export const ENROLLMENT_TYPES = [
    { value: "Enrollment", label: "Beiratkozás" },
    { value: "Dismissal", label: "Kiiratkozás" },
];

const getAddressDetail = (value) =>
    value && value !== "null" ? " " + value : "";
export const createAddressStringFromObject = (object) =>
    object
        ? `${getAddressDetail(object.postCode)}${
              object.city ? " " + object.city + "," : ""
          }${getAddressDetail(object.streetName)}${getAddressDetail(
              object.streetType
          )}${getAddressDetail(object.houseNumber)}${getAddressDetail(
              object.floor
          )}${getAddressDetail(object.door)}`
        : "";

export const getEnrollmentLabel = (value) =>
    ENROLLMENT_TYPES.find((type) => type.value === value)?.label;

export const PERMISSIONS = {
    SYSTEM_ADMIN: "ROLE_SYSTEM_ADMIN",
    UNIVERSITY_ADMIN: "ROLE_UNIVERSITY_ADMIN",
    FINANCIAL_ADMIN: "ROLE_FINANCIAL_ADMIN",
    OPERATOR_ADMIN: "ROLE_OPERATOR_ADMIN",
    STUDENT: "ROLE_STUDENT",
};

export const LAYOUT_TYPES = {
    STUDENT: "student",
    ADMIN: "admin"
}

export const PERMISSIONS_WITH_USER = { ...PERMISSIONS, USER: "ROLE_USER" };

export const BOOLEAN_OPTIONS = [
    { value: "true", label: "Igen" },
    { value: "false", label: "Nem" },
];

export const ACTIVE_OPTIONS = [
    { value: "true", label: "Aktív" },
    { value: "false", label: "Inaktív" },
];

export const getDocumentField = (field, form) => {
    let val = field.name;

    if (field.required === "1") {
        return val;
    }

    if (form.consentedDocuments.find((x) => x.id === field.id)) {
        val = val + " (Kép-, hang- és videófelvételhez hozzájárult)";
    } else {
        val = val + " (Kép-, hang- és videófelvételhez nem járult hozzá)";
    }

    return val;
};

export const getValue = (field, form) => {
    let val = form[field.value];
    const displayableFields = [
        "fullName",
        "motherFullName",
        "birthPlace",
        "birthDate",
        "phoneNumber",
        "universityMajor",
        "idCardNumber",
    ];

    if (field.value === "acceptedDocuments") {
        return form["acceptedDocuments"]
            ?.map((f) => getDocumentField(f, form))
            .join(", ");
    }

    const displayableFieldsByName = [
        "campus",
        "room",
        "building",
        "floor",
        "university",
        "trainingLevel",
        "trainingProgram",
        "collegeYear",
    ];

    if (displayableFields.includes(field.value)) {
        val = form[field.value] || "";
    } else if (displayableFieldsByName.includes(field.value)) {
        val = form[field.value]?.name || "";
    } else if (field.value === "membershipStatus") {
        val = membershipStatusLabel(form[field.value]);
    } else if (field.value === "currentMembershipStatus") {
        val = membershipStatusLabel(form.currentMembershipStatus);
    } else if (field.value === "semesterStatus") {
        val = semesterStatusLabel(form.semesterStatus);
    } else if (field.value === "type") {
        val = getEnrollmentLabel(form.type ? (form.type.charAt(0).toUpperCase() + form.type.slice(1).toLowerCase()) : "");
    } else if (field.value === "address") {
        val = createAddressStringFromObject(form[field.value]);
    } else if (field.value === "trainingForm") {
        val = getTrainingFormLabelByValue(form[field.value]);
    } else if (field.value === "financingForm") {
        val = getFinancingFormLabelByValue(form[field.value]);
    } else if (field.value === "scholarshipStatus") {
        val = getStudentStatusLabelByValue(form[field.value]);
    }

    return val;
};

export const subMenus = {
    translations: {
        to: "/translations",
        icon: faGlobe,
        text: "Nyelvesítések",
    },
    emailTemplates: {
        to: "/email-templates",
        icon: faEnvelope,
        text: "E-mail sablonok",
    },
    emailLog: {
        to: "/email-log",
        icon: faBook,
        text: "E-mail napló",
    },
    systemLog: {
        to: "/system-log",
        icon: faServer,
        text: "Rendszernapló",
    },
    users: {
        to: "/users",
        icon: faUser,
        text: "Felhasználók",
    },
    universities: {
        to: "/universities",
        icon: faUniversity,
        text: "Egyetemek",
    },
    campuses: {
        to: "/campuses",
        icon: faBuilding,
        text: "Campusok",
    },
    semesters: {
        to: "/semesters",
        icon: faCalendarAlt,
        text: "Szemeszterek",
    },
    trainingLevels: {
        to: "/training-levels",
        icon: faBookOpen,
        text: "Képzések",
    },
    students: {
        to: "/students",
        icon: faGraduationCap,
        text: "Hallgatók",
    },
    processesTabs: {
        to: "/processes",
        icon: faSpinner,
        text: "Folyamatok listája",
    },
    permissions: {
        to: "/permissions",
        icon: faLock,
        text: "Jogosultságok",
    },
    newProcess: {
        to: "/new-process",
        icon: faPlus,
        text: "Új folyamat",
    },
    roomAllocationList: {
        to: "/room-allocation-list",
        icon: faList,
        text: "Szobabeosztás (szoba)",
    },
    studentAllocationPreferenceList: {
        to: "/student-allocation-preference-list",
        icon: faList,
        text: "Szobabeosztás (diák)",
    },
    studentsList: {
        to: "/enrollments-list",
        icon: faList,
        text: "Beiratkozások",
    },
    relocationsList: {
        to: "/relocations-list",
        icon: faList,
        text: "Átköltözések",
    },
    terminationsList: {
        to: "/terminations-list",
        icon: faList,
        text: "Kiiratkozások",
    },
    moveoutList: {
        to: "/moveout-list",
        icon: faList,
        text: "Kiköltözések",
    },
    sync: {
        to: "/sync",
        icon: faSync,
        text: "Szinkronizáció",
    },
};

export const mainMenus = {
    masterData: {
        name: "Törzsadatok",
        subMenus: [
            subMenus.users,
            subMenus.students,
            subMenus.campuses,
            subMenus.universities,
            subMenus.trainingLevels,
            subMenus.semesters,
        ],
    },
    administration: {
        name: "Adminisztráció",
        subMenus: [
            subMenus.emailTemplates,
            subMenus.emailLog,
            // subMenus.translations,
            subMenus.permissions,
            subMenus.systemLog,
            subMenus.sync,
        ],
    },
    processes: {
        name: "Folyamatok",
        subMenus: [subMenus.processesTabs, subMenus.newProcess],
    },
    reports: {
        name: "Riportok",
        subMenus: [
            subMenus.studentsList,
            subMenus.terminationsList,
            subMenus.moveoutList,
            subMenus.roomAllocationList,
            subMenus.studentAllocationPreferenceList,
            subMenus.relocationsList,
        ],
    },
};

export const VALIDATION_MESSAGES = {
    required: "Ez az érték nem lehet üres",
    email: "Kérjük érvényes e-mail formátumot adjon meg",
};

export const identityNumberTooltipText =
    "A  személyi számodat a lakcímkártyád plasztikján találod, a hátsó, vonalkóddal ellátott oldalon, „Személyi azonosító” néven.";

export const OPEN_ENROLLMENT_STATES = {
    financial_check: "Pénzügyi ellenőrzés",
    filling_approval: "Jóváhagyás",
    operator_check: "Üzemeltetési ellenőrzés",
    room_approval: "Szobajóváhagyás",
    room_take_over: "Szoba átvételi jegyzőkönyv",
    student_filling: "Beiratkozás",
};

export const enrollmentStatus = {
    ...OPEN_ENROLLMENT_STATES,
    done: "Lezárult",
};

export const RELOCATION_STATUSES = {
    financial_check: "Pénzügyi ellenőrzés",
    unpaid_debt: "Pénzügyi ellenőrzés", //TODO: szűrésben kezelni kell, hogy a pénzügyi ellenőrzés hozza mindkettőt
    filling_approval: "Jóváhagyás",
    operator_room_take_over: "Üzemeltetési szobaátvétel",
    student_room_take_over: "Szobaátvételi nyilatkozat kitöltése",
    approve_room_take_over: "Üzemeltetői ellenőrzés",
    room_approval: "Szobajóváhagyás",
    room_take_over: "Szoba átvételi jegyzőkönyv",
    move_out_form: "Kiköltözési nyilatkozat",
    student_filling: "Hallgató tölti ki",
    done: "Lezárult",
};

export const TERMINATION_STATUSES = {
    financial_check: "Pénzügyi ellenőrzés",
    student_declaration: "Hallgatói nyilatkozat",
    operator_check: "Üzemeltetési ellenőrzés",
    done: "Lezárult",
};

export const STUDENT_ALLOCATION_PREFERENCE_STATUSES = {
    student_filling: "Kitöltés alatt",
    done: "Lezárult",
};

export const PROCESS_STATUS_OPTIONS = {
    student_filling_enrollment: "Beiratkozás",
    student_filling_preference: "Kitöltés alatt",
    student_filling_relocation: "Hallgató tölti ki",
    financial_check: "Pénzügyi ellenőrzés",
    filling_approval: "Jóváhagyás",
    operator_check: "Üzemeltetési ellenőrzés",
    operator_room_take_over: "Üzemeltetési szobaátvétel",
    student_room_take_over: "Szobaátvételi nyilatkozat kitöltése",
    approve_room_take_over: "Üzemeltetői ellenőrzés",
    room_approval: "Szobajóváhagyás",
    room_take_over: "Szoba átvételi jegyzőkönyv",
    move_out_form: "Kiköltözési nyilatkozat",
};

export const PROCESS_STATUS_OPTIONS_DETAILED = [
    // Beiratkozás
    {
        value: "student_filling",
        label: "Beiratkozás",
        type_hu: "Beiratkozás",
        type_en: "enrollment",
    },
    {
        value: "financial_check",
        label: "Pénzügyi ellenőrzés",
        type_hu: "Beiratkozás",
        type_en: "enrollment",
    },
    {
        value: "filling_approval",
        label: "Jóváhagyás",
        type_hu: "Beiratkozás",
        type_en: "enrollment",
    },
    {
        value: "room_approval",
        label: "Szobajóváhagyás",
        type_hu: "Beiratkozás",
        type_en: "enrollment",
    },
    {
        value: "room_take_over",
        label: "Szoba átvételi jegyzőkönyv",
        type_hu: "Beiratkozás",
        type_en: "enrollment",
    },
    {
        value: "operator_check",
        label: "Üzemeltetési jegyzőkönyv",
        type_hu: "Beiratkozás",
        type_en: "enrollment",
    },
    // Szobatárspreferencia
    {
        value: "student_filling",
        label: "Kitöltés alatt",
        type_hu: "Szobatárspreferencia",
        type_en: "student_allocation_preference",
    },
    // Átköltözés
    {
        value: "student_filling",
        label: "Hallgató tölti ki",
        type_hu: "Átköltözés",
        type_en: "relocation",
    },
    {
        value: "financial_check_relocation",
        label: "Pénzügyi ellenőrzés",
        type_hu: "Átköltözés",
        type_en: "relocation",
    },
    {
        value: "move_out_form",
        label: "Kiköltözési nyilatkozat",
        type_hu: "Átköltözés",
        type_en: "relocation",
    },
    {
        value: "operator_room_take_over",
        label: "Üzemeltetési szobaátvétel",
        type_hu: "Átköltözés",
        type_en: "relocation",
    },
    {
        value: "student_room_take_over",
        label: "Szobaátvételi nyilatkozat kitöltése",
        type_hu: "Átköltözés",
        type_en: "relocation",
    },
    {
        value: "approve_room_take_over",
        label: "Üzemeltetői ellenőrzés",
        type_hu: "Átköltözés",
        type_en: "relocation",
    },
    // Kiiratkozás
    {
        value: "student_declaration",
        label: "Hallgatói nyilatkozat",
        type_hu: "Kiiratkozás",
        type_en: "termination",
    },
    {
        value: "financial_check",
        label: "Pénzügyi ellenőrzés",
        type_hu: "Kiiratkozás",
        type_en: "termination",
    },
    {
        value: "operator_check",
        label: "Üzemeltetési ellenőrzés",
        type_hu: "Kiiratkozás",
        type_en: "termination",
    },
    // Kiköltözés
    {
        value: "student_declaration",
        label: "Hallgatói nyilatkozat",
        type_hu: "Kiköltözés",
        type_en: "move_out",
    },
    {
        value: "financial_check",
        label: "Pénzügyi ellenőrzés",
        type_hu: "Kiköltözés",
        type_en: "move_out",
    },
    {
        value: "operator_check",
        label: "Üzemeltetési ellenőrzés",
        type_hu: "Kiköltözés",
        type_en: "move_out",
    },
];

export const enrollmentStatusByRole = {
    [PERMISSIONS.FINANCIAL_ADMIN]: [
        "financial_check",
        "filling_approval",
        "room_approval",
        "room_take_over",
        "operator_check",
    ],
    [PERMISSIONS.OPERATOR_ADMIN]: ["operator_check"],
    [PERMISSIONS.UNIVERSITY_ADMIN]: [
        "financial_check",
        "filling_approval",
        "room_approval",
        "room_take_over",
        "operator_check",
        "student_filling",
    ],
};

function enrollmentStatusFiltered(data) {
    const existingEntries = new Set(data?.map((e) => e.status));
    const filtered = {};
    for (const [key, value] of Object.entries(enrollmentStatus)) {
        if (existingEntries.has(key)) {
            filtered[key] = value;
        }
    }
    return filtered;
}

function studentAllocationPreferenceStatusFiltered(data) {
    const existingEntries = new Set(data?.map((e) => e.status));
    const filtered = {};
    for (const [key, value] of Object.entries(
        STUDENT_ALLOCATION_PREFERENCE_STATUSES
    )) {
        if (existingEntries.has(key)) {
            filtered[key] = value;
        }
    }
    return filtered;
}

// Ez a függvény arra szolgált, hogy a folyamatstátusz szűrőben csak olyan értékek legyenek, amik a táblázatban is
export function processStatusFiltered(data) {
    let dataHasEnrollment = false;
    let dataHasPreference = false;

    data?.forEach((element) => {
        if (element.type === "enrollment") dataHasEnrollment = true;
        if (element.type === "student_allocation_preference")
            dataHasPreference = true;
    });

    const enrollmentStatusesFiltered = dataHasEnrollment
        ? enrollmentStatusFiltered(data)
        : {};
    const studentAllocationPreferenceStatusesFiltered = dataHasPreference
        ? studentAllocationPreferenceStatusFiltered(data)
        : {};

    if ("student_filling" in enrollmentStatusesFiltered) {
        enrollmentStatusesFiltered["student_filling_enrollment"] =
            "Beiratkozás";
        delete enrollmentStatusesFiltered["student_filling"];
    }

    if ("student_filling" in studentAllocationPreferenceStatusesFiltered) {
        studentAllocationPreferenceStatusesFiltered[
            "student_filling_preference"
        ] = "Kitöltés alatt";
        delete studentAllocationPreferenceStatusesFiltered["student_filling"];
    }

    const result = {
        ...enrollmentStatusesFiltered,
        ...studentAllocationPreferenceStatusesFiltered,
    };

    return result;
}

export function enrollmentStatusByPermission() {
    if (hasRole(PERMISSIONS.SYSTEM_ADMIN)) return OPEN_ENROLLMENT_STATES;
    const available = [];
    for (const [role, status] of Object.entries(enrollmentStatusByRole)) {
        if (hasRole(role)) {
            available.push(...status);
        }
    }
    const availableStatus = {};
    for (const status of new Set(available)) {
        availableStatus[status] = enrollmentStatus[status];
    }
    return availableStatus;
}

export function translateEnrollmentStatus(status) {
    if (status in enrollmentStatus) {
        return enrollmentStatus[status];
    }

    return "Ismeretlen állapot";
}

export function translateTerminationStatus(status) {
    if (status in TERMINATION_STATUSES) {
        return TERMINATION_STATUSES[status];
    }

    return "Ismeretlen állapot";
}

export function translateStudentAllocationPreferenceStatus(status) {
    if (status in STUDENT_ALLOCATION_PREFERENCE_STATUSES) {
        return STUDENT_ALLOCATION_PREFERENCE_STATUSES[status];
    }

    return "Ismeretlen állapot";
}

export function translateRelocationStatus(status) {
    if (status in RELOCATION_STATUSES) {
        return RELOCATION_STATUSES[status];
    }

    return "Ismeretlen állapot";
}

export function semesterStatus(status) {
    return (
        semesterStatusOptions.filter((s) => s.value === status)?.[0]?.label ||
        ""
    );
}

export function translateGender(gender) {
    return genderOptions.filter((g) => g.value === gender)?.[0]?.label || "";
}

export const EMAIL_LOG_STATUS_OPTIONS = [
    { value: "generation", label: "Generálás" },
    { value: "waiting", label: "Várakozó" },
    { value: "error", label: "Hiba" },
    { value: "success", label: "Kiküldve" },
];

export const ENROLLMENT_HISTORY_STATUSES = [
    { en: "started", hu: "Beiratkozási folyamat indítása" },
    { en: "student_form_filled", hu: "Beiratkozás" },
    { en: "financial_check_approved", hu: "Pénzügyi ellenőrzés" },
    { en: "student_filling_approved", hu: "Jóváhagyás" },
    { en: "room_approved", hu: "Szobajóváhagyás" },
    { en: "room_taken_over", hu: "Szoba átvételi jegyzőkönyv" },
    { en: "operator_check_approved", hu: "Üzemeltetői ellenőrzés" },
];

export const ENROLLMENT_HISTORY_STATUSES_ARRAY = [
    "started",
    "student_form_filled",
    "financial_check_approved",
    "student_filling_approved",
    "room_approved",
    "room_taken_over",
    "operator_check_approved",
];

export function numberFormat(val) {
    if (val === 0) {
        return "0";
    }
    if (!val) {
        return "";
    }
    return val
        .toLocaleString(navigator.language, { minimumFractionDigits: 0 })
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
}

export const ASSIGN_STUDENTS_STATUSES = {
    ACTION_ASSIGN_STUDENTS_STARTED: "assign_students_started",
    ACTION_ASSIGN_STUDENTS_DONE: "assign_students_done",
    ACTION_ASSIGN_STUDENTS_ERROR: "assign_students_error",
};

export function fixDateRangeFields(form) {
    const fixedForm = {};
    Object.entries(form).forEach(([key, value]) => {
        const newKey = key
            .replace(/(.*)From/i, "$1[after]")
            .replace(/(.*)To/i, "$1[before]");
        fixedForm[newKey] = value;
    });
    return fixedForm;
}

export const createQueryParams = (params) =>
    Object.keys(params)
        .map((k) => `${k}=${encodeURI(params[k])}`)
        .join("&");

export const axiosErrorRedirect = (error) => {
    const params = {
        target: "tenant_" + (process.env.REACT_APP_TENANT_ID || 1) + "_enrollment",
        errorStatus: error.response?.data?.messageData?.status || "unknown",
    };
    window.location.href = `${
        process.env.REACT_APP_AUTH_URL || "https://auth01.mcc.hu/"
    }?${createQueryParams(params)}`;
};

export const ARCHIVE_OPTIONS = [
    { value: "1", label: "Igen" },
    { value: "0", label: "Nem" },
];

export const bankAccountFormatter = (onChange, event) => {
    event.stopPropagation();
    let value = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
    const key = value.slice(-1);
    const characterRegex = /^[a-zA-Z0-9]+$/;
    const cursorPos = event.target.selectionStart;
    let newCursorPos = cursorPos;

    if (value.length === 25 && !isNaN(parseInt(value.slice(0, 2)))) {
        return;
    }
    if (value.length > 25 && !isNaN(parseInt(value.slice(0, 2)))) {
        value = value.slice(0, 24);
    }
    if (!key || (characterRegex.test(key) && value.length < 35)) {
        let transformedValue = value;
        if (value.length >= 2 && isNaN(parseInt(value.slice(0, 2)))) {
            transformedValue =
                value
                    .toUpperCase()
                    .match(/.{1,4}/g)
                    ?.join(" ") || "";
            newCursorPos += Math.floor((cursorPos - 1) / 4);
        } else if (value.length >= 2 && !isNaN(parseInt(value.slice(0, 2)))) {
            transformedValue = value.match(/.{1,8}/g)?.join("-") || "";
            newCursorPos += Math.floor((cursorPos - 1) / 8);
        }
        onChange(transformedValue);
        event.target.value = transformedValue;
        event.target.selectionStart = newCursorPos;
        event.target.selectionEnd = newCursorPos;
    }
};

export function formatBankAccountNumberForDisplay(bankAccountNumber) {
    if (!bankAccountNumber) return;

    bankAccountNumber = (bankAccountNumber || "").replace(/[-\s]/g, "");
    let formattedBankAccountNumber = "";
    if (
        bankAccountNumber.length >= 2 &&
        isNaN(bankAccountNumber[0]) &&
        isNaN(bankAccountNumber[1])
    ) {
        formattedBankAccountNumber =
            bankAccountNumber.match(/.{1,4}/g)?.join(" ") || "";
    } else if (
        bankAccountNumber.length >= 2 &&
        !isNaN(bankAccountNumber[0]) &&
        !isNaN(bankAccountNumber[1])
    ) {
        formattedBankAccountNumber =
            bankAccountNumber.match(/.{1,8}/g)?.join("-") || "";
    }
    return formattedBankAccountNumber;
}

export function isKep()  {
    return process.env.REACT_APP_TENANT_ID === '2';
}

export const customReplacer = (key, value) => {
    if (typeof value === 'function' || value instanceof Node || value === undefined) {
        return undefined;
    }
    return value;
};
