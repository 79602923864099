import {
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Typography,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import enrollmentActions from '../../../../store/processes/newProcess/enrollment/actions';
import StyledCancelButton from '../../../Common/CancelButton';
import SubmitButton from '../../../Common/SubmitButton';
import TextField from '../../../Common/TextField';
import Title from '../../../Common/RawTitle';
import instance from '../../../../utils/API';
import { useParams } from 'react-router-dom';
import { downloadFile } from './utils';
import {withStyles} from "@material-ui/core/styles";
import BasicButton from "@material-ui/core/Button";
import colors from '../../../../styles/colors';
import Autocomplete from '../../../Common/Autocomplete';
import { options } from './options';

const StyledButton = withStyles({
    root: {
        background: colors.error,
        borderRadius: 0,
        border: 0,
        color: colors.white,
        height: 35,
        padding:'0 20px',
        boxShadow: 'black',
        '&:hover': {
            background: colors.errorHover,
        },
        '&:disabled': {
            background: colors.disabledBackground,
            color: colors.disabledColor,
        }
    },
    label: {
        textTransform: 'none'
    },
})(BasicButton);

function CertificateDetails(props) {
    const [form, setForm] = useState(props.certificateForm);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const handleChange = (event) => {
        const { name, value } = event.target;
        setForm((prev) => ({ ...prev, [name]: value }));
    };
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setForm(props.certificateForm);
    }, [props.certificateForm]);

    const handleSubmit = () => {
        setErrors({});
        const formData = new FormData();
        formData.append('institute', form.institute);
        formData.append('major', form.major);
        formData.append('trainingType', form.trainingType?.value || form.trainingType);
        if (form.file) {
            formData.append('file', form.file);
        } else {
            formData.append('certificateFileName', form.certificateFileName);
            formData.append('certificateFilePath', form.certificateFilePath);
        }

        setLoading(true);
        instance.post(
            `/enrollments/${id}/fill-student/certificate${props.certificateForm.id ? '/' + props.certificateForm.id : ''}`, 
            formData
        ).then(() => {
            props.getStudentCertificate(id).then(() => {
                setForm(null);
                setLoading(false);
            })
        })
        .catch((error) => {
            const violations = error.response?.data?.violations;

            let tmpErrors = {};
            violations?.forEach((violation) => {
                tmpErrors[violation.propertyPath] = violation.message;
            });

            setErrors(tmpErrors);
            setLoading(false);
        })
    }

    const handleDownload = () => {
        instance.get(`/enrollments/${id}/fill-student/certificate/${form.id}/download-file`, {responseType: 'blob'})
            .then((response) => {
                downloadFile(response.data, form.certificateFileName);
            });
    }

    return (
        <Dialog
            open={!!form}
            onClose={() => {
                props.setCertificateForm(null);
                setErrors({});
            }}
            maxWidth='md'
            fullWidth
        >
            {form && (
                <>
                    <DialogContent>
                        <Typography variant='h5' paragraph={true}>Végzettség {form.id ? "szerkesztése" : "hozzáadása"}</Typography>
                        <Title title='Intézmény'>
                        <TextField
                                name='institute'
                                value={form.institute || ''}
                                onChange={handleChange}
                                error={errors.institute}
                                helperText={errors.institute}
                            />
                        </Title>
                        <Title title='Szak'>
                            <TextField
                                name='major'
                                value={form.major || ''}
                                onChange={handleChange}
                                error={errors.major}
                                helperText={errors.major}
                            />
                        </Title>
                        <Title title='Képzés formája'>
                        <Autocomplete
                                name='trainingType'
                                value={form.trainingType || ''}
                                options={options.trainingFormOptions}
                                multiple={false}
                                onChange={(_, v) => {
                                    setForm((prev) => ({
                                        ...prev,
                                        trainingType: v,
                                    }));
                                }}
                                error={errors.trainingType}
                                helperText={errors.trainingType}
                            />
                        </Title>
                        <Title title='Végzettséget igazoló dokumentum'>
                        {form.certificateFileName && form.certificateFilePath ? 
                        (   
                            <Grid container spacing={2}>
                                <Grid item>
                                    <SubmitButton
                                        onClick={handleDownload}
                                        >
                                        Letöltés
                                    </SubmitButton>
                                </Grid>
                                <Grid item>
                                    <StyledButton
                                        color="error"
                                        variant="contained"
                                        onClick={() => setForm((prev) => {
                                            return({
                                                ...prev,
                                                certificateFileName: '',
                                                certificateFilePath: '',
                                            })
                                        })}
                                    >
                                        Törlés
                                    </StyledButton>
                                </Grid>
                                <Grid item align="center" alignItems = "center" >{form.certificateFileName}</Grid>
                            </Grid>
                        ) : 
                        (<TextField
                                name='file'
                                type='file'
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ accept: '.pdf' }}
                                onChange={(e) => {
                                    setForm((prev) => {
                                        return ({
                                            ...prev,
                                            file:
                                                e.target.files[0],
                                        })
                                    });
                                }}
                                error={errors.file}
                                helperText={errors.file}
                            />)}
                        </Title>
                    </DialogContent>
                    <DialogActions>
                        <StyledCancelButton onClick={() => {
                            props.setCertificateForm(null);
                            setErrors({});
                        }} color='primary'>
                            Mégse
                        </StyledCancelButton>
                        <SubmitButton
                            onClick={handleSubmit}
                            color='secondary'
                            disabled={
                                loading
                            }
                        >
                            Mentés
                        </SubmitButton>
                    </DialogActions>
                </>
            )}
        </Dialog>
    );
}

function mapState(state) {
    const { form } = state.enrollment;
    return { form };
}

const actionCreators = {
    changeForm: enrollmentActions.changeForm,
    defaultForm: enrollmentActions.defaultForm,
    getStudentCertificate: enrollmentActions.getStudentCertificate
};

export default connect(mapState, actionCreators)(CertificateDetails);
