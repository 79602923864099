import {
    history,
    templateChangeForm,
    templateDefaultForm,
    templateFilter,
    templateGet,
    templateDelete,
} from "../../..";
import enrollmentService from "../../../../services/processes/enrollment";
import { types } from "./types";
import notificationTypes from "../../../notification/types";
import loadingTypes from "../../../loading/types";

const changeEditor = (content) => (dispatch) =>
    dispatch({ type: types.CHANGE_EDITOR, content });

function startEnrollment(form) {
    const students = form.students?.map((student) => {
        return parseInt(student.split("/")?.pop());
    });

    const formToSend = {
        students: students,
        emailSubject: form.emailSubject,
        emailContent: form.emailContent,
    };

    return (dispatch) => {
        dispatch({ type: types.START_REQUEST, formToSend });
        return enrollmentService.startEnrollment(formToSend).then(
            (data) => {
                dispatch({ type: types.START_SUCCESS, data });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: { type: "success", msg: "Sikeres küldés" },
                });
                dispatch({ type: types.DEFAULT_FORM });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.START_FAILURE, error });
                dispatch({
                    type: notificationTypes.ADD_NOTIFICATION,
                    data: {
                        type: "error",
                        msg: "Hiba lépett fel küldés közben",
                    },
                });
                return Promise.reject(error);
            }
        );
    };
}

function fillStudentForm(form, id, selectedFile, url) {
    let phoneNumber = form.phoneNumber;

    // Backend-en csak számokat fogadunk el, ezért a mezőbe ütött "+"-ot levágjuk
    if (phoneNumber && phoneNumber.charAt(0) === "+") {
        phoneNumber = phoneNumber?.substring(1);
    }

    const formToSend = {
        ...form,
        phoneNumber: phoneNumber,
    };

    return (dispatch) => {
        dispatch({ type: types.FILL_STUDENT_FORM_REQUEST, formToSend });
        return enrollmentService
            .fillStudentForm(formToSend, id, selectedFile)
            .then(
                (data) => {
                    dispatch({ type: types.FILL_STUDENT_FORM_SUCCESS, data });
                    dispatch({
                        type: notificationTypes.ADD_NOTIFICATION,
                        data: { type: "success", msg: "Sikeres mentés" },
                    });
                    if (url) history.push(url);
                    return Promise.resolve(data);
                },
                (error) => {
                    dispatch({ type: types.FILL_STUDENT_FORM_FAILURE, error });
                    dispatch({
                        type: notificationTypes.ADD_NOTIFICATION,
                        data: {
                            type: "error",
                            msg: "Hiba lépett fel mentés közben",
                        },
                    });
                    return Promise.reject(error);
                }
            );
    };
}

function filterActive(form) {
    let formToSend = Object.assign({}, form);

    if (formToSend?.status) {
        formToSend.status = formToSend?.status.flatMap(element => {
            if (element === "financial_check_relocation") {
                return ["financial_check", "unpaid_debt"];
            }

            return element;
        });
    }

    return (dispatch) => {
        dispatch({ type: types.FILTER_ACTIVE_REQUEST, formToSend });
        dispatch({ type: loadingTypes.TABLE_LOADING_ON });
        return enrollmentService.filterActive(formToSend).then(
            (data) => {
                dispatch({ type: types.FILTER_ACTIVE_SUCCESS, data });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FILTER_ACTIVE_FAILURE, error });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.reject(error);
            }
        );
    };
}

function filterDone(form) {
    return (dispatch) => {
        dispatch({ type: types.FILTER_DONE_REQUEST, form });
        dispatch({ type: loadingTypes.TABLE_LOADING_ON });
        return enrollmentService.filterDone(form).then(
            (data) => {
                dispatch({ type: types.FILTER_DONE_SUCCESS, data });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FILTER_DONE_FAILURE, error });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.reject(error);
            }
        );
    };
}

function getStudentOngoing(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_STUDENT_ONGOING_REQUEST, id });
        dispatch({ type: loadingTypes.TABLE_LOADING_ON });
        return enrollmentService.getStudentOngoingProcesses(id).then(
            (data) => {
                dispatch({ type: types.GET_STUDENT_ONGOING_SUCCESS, data });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_STUDENT_ONGOING_FAILURE, error });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.reject(error);
            }
        );
    };
}

function getStudentDone(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_STUDENT_DONE_REQUEST, id });
        dispatch({ type: loadingTypes.TABLE_LOADING_ON });
        return enrollmentService.getStudentDoneProcesses(id).then(
            (data) => {
                dispatch({ type: types.GET_STUDENT_DONE_SUCCESS, data });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_STUDENT_DONE_FAILURE, error });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.reject(error);
            }
        );
    };
}

function getAvailableDocuments(enrollmentId) {
    const request = {
        enrollment: enrollmentId,
    };

    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST, request });

        return enrollmentService.getAvailableDocuments(enrollmentId).then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getNationalities() {
    const request = {};

    return (dispatch) => {
        dispatch({ type: types.FETCH_REQUEST, request });

        return enrollmentService.getNationalities().then(
            (data) => {
                dispatch({ type: types.FETCH_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.FETCH_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getData(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_DATA_REQUEST, id });

        return enrollmentService.get(id).then(
            (data) => {
                dispatch({ type: types.GET_DATA_SUCCESS, data });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_DATA_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}

function getEnrollmentHistory(id) {
    return (dispatch) => {
        dispatch({ type: types.GET_ENROLLMENT_HISTORY_REQUEST, id });
        return enrollmentService.getEnrollmentHistory(id).then(
            (data) => {
                dispatch({ type: types.GET_ENROLLMENT_HISTORY_SUCCESS, data });
                return Promise.resolve;
            },
            (error) => {
                dispatch({ type: types.GET_ENROLLMENT_HISTORY_FAILURE, error });
                return Promise.reject(error);
            }
        );
    };
}
const changeActiveCloseTab = (tabId) => (dispatch) =>
    dispatch({ type: types.CHANGE_ACTIVE_CLOSE_TAB, data: tabId });

function setActiveInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_ACTIVE_INFO, key, value });
    };
}

function setClosedInfo(key, value) {
    return (dispatch) => {
        return dispatch({ type: types.FETCH_SET_CLOSED_INFO, key, value });
    };
}

const deleteStudentAllocationPreference = (id) => (dispatch) => {
    dispatch({ type: types.DELETE_REQUEST });
    return enrollmentService.deleteStudentAllocationPreference(id).then(
        (data) => {
            dispatch({ type: types.DELETE_SUCCESS, data });
            dispatch({
                type: notificationTypes.ADD_NOTIFICATION,
                data: { type: "success", msg: "Sikeres törlés" },
            });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.DELETE_FAILURE, error });
            dispatch({
                type: notificationTypes.ADD_NOTIFICATION,
                data: { type: "error", msg: "Hiba lépett fel törlés közben" },
            });
            return Promise.reject(error);
        }
    );
};

const deleteTermination = (id) => (dispatch) => {
    dispatch({ type: types.DELETE_REQUEST });
    return enrollmentService.deleteTermination(id).then(
        (data) => {
            dispatch({ type: types.DELETE_SUCCESS, data });
            dispatch({
                type: notificationTypes.ADD_NOTIFICATION,
                data: { type: "success", msg: "Sikeres törlés" },
            });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.DELETE_FAILURE, error });
            dispatch({
                type: notificationTypes.ADD_NOTIFICATION,
                data: { type: "error", msg: "Hiba lépett fel törlés közben" },
            });
            return Promise.reject(error);
        }
    );
};

const getForm = (id) => (dispatch) => {
    dispatch({ type: types.GET_FORM_REQUEST });
    return enrollmentService.getForm(id).then(
        (data) => {
            dispatch({ type: types.GET_FORM_SUCCESS, data });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({ type: types.GET_FORM_FAILURE, error });
            return Promise.reject(error);
        }
    );
};

function getActualUniversityStudies(enrollmentId) {
    return (dispatch) => {
        dispatch({ type: types.GET_ACTUAL_UNIVERSITY_STUDIES_REQUEST, enrollmentId });
        dispatch({ type: loadingTypes.TABLE_LOADING_ON });
        return enrollmentService.getActualUniversityStudies(enrollmentId).then(
            (data) => {
                dispatch({ type: types.GET_ACTUAL_UNIVERSITY_STUDIES_SUCCESS, data });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_ACTUAL_UNIVERSITY_STUDIES_FAILURE, error });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.reject(error);
            }
        );
    };
}

function getStudentCertificate(enrollmentId) {
    return (dispatch) => {
        dispatch({ type: types.GET_STUDENT_CERTIFICATE_REQUEST, enrollmentId });
        dispatch({ type: loadingTypes.TABLE_LOADING_ON });
        return enrollmentService.getStudentCertificate(enrollmentId).then(
            (data) => {
                dispatch({ type: types.GET_STUDENT_CERTIFICATE_SUCCESS, data });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.resolve(data);
            },
            (error) => {
                dispatch({ type: types.GET_STUDENT_CERTIFICATE_FAILURE, error });
                dispatch({ type: loadingTypes.TABLE_LOADING_OFF });
                return Promise.reject(error);
            }
        );
    };
}

const actions = {
    changeForm: templateChangeForm(types),
    defaultForm: templateDefaultForm(types),
    filter: templateFilter(types, enrollmentService),
    filterActive,
    filterDone,
    get: templateGet(types, enrollmentService),
    delete: templateDelete(types, enrollmentService),
    getForm,
    getData,
    getStudentOngoing,
    getStudentDone,
    changeEditor,
    startEnrollment,
    fillStudentForm,
    getAvailableDocuments,
    getNationalities,
    getEnrollmentHistory,
    getActualUniversityStudies,
    getStudentCertificate,
    changeActiveCloseTab,
    setActiveInfo,
    setClosedInfo,
    deleteStudentAllocationPreference,
    deleteTermination,
};

export default actions;
