import getCurrentPage from "../../../../utils/getCurrentPage";
import { types } from "./types";

const initialState = {
    form: {},
    getData: {},
    data: [],
    dataActive: [],
    dataDone: [],
    enrollmentHistory: [],
    activeCloseTab: 0,
    tableInfoActive: {
        filter: {},
        order: {},
        page: 1,
        itemsPerPage: 25,
        metaData: {}
    },
    tableInfoDone: {
        filter: {},
        order: {},
        page: 1,
        itemsPerPage: 25,
        metaData: {}
    },
    studentOngoing: [],
    studentDone: []
}

export function enrollment(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_FORM:
            return {
                ...state,
                [action.data.name]: action.data.form
            }
        case types.CHANGE_ACTIVE_CLOSE_TAB:
            return {
                ...state,
                ...initialState,
                activeCloseTab: action.data
            }
        case types.DEFAULT_FORM:
            return {
                ...state,
                ...initialState,
                [action.name]: initialState[action.name],
                form: {
                    ...initialState.form,
                    campus: state.form.campus
                }
            }
        case types.FETCH_SET_ACTIVE_INFO:
            return {
                ...state,
                tableInfoActive: {
                    ...state.tableInfoActive,
                    [action.key]: action.value,
                },
            };
        case types.FETCH_SET_CLOSED_INFO:
            return {
                ...state,
                tableInfoDone: {
                    ...state.tableInfoDone,
                    [action.key]: action.value,
                },
            };
        case types.CHANGE_EDITOR:
            return {
                ...state,
                form: {
                    ...state.form,
                    //content: action.content,
                    emailContent: action.content
                }
            }
        case types.FETCH_REQUEST:
            return {
                ...state,
            };
        case types.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.data.data,
            };
        case types.FETCH_FAILURE:
            return {
                ...state,
                data: [],
                error: action.error,
            };
        case types.FILTER_ACTIVE_REQUEST:
            return {
                ...state,
            };
        case types.FILTER_ACTIVE_SUCCESS:
            return {
                ...state,
                loading: false,
                tableInfoActive: {
                    ...state.tableInfoActive,
                    metadata: {
                        'allCount': action?.data?.data['hydra:totalItems'],
                        'filteredCount': action?.data?.data['hydra:totalItems'],
                        'lastPage': action?.data?.data['hydra:totalItems'] ? Math.ceil(action?.data?.data['hydra:totalItems'] / state.tableInfoActive.itemsPerPage) : 1,
                        'page': getCurrentPage(action?.data?.data['hydra:view']),
                        'itemsPerPage': state.tableInfoActive.itemsPerPage,
                    }
                },
                dataActive: action.data.data,
            };
        case types.FILTER_ACTIVE_FAILURE:
            return {
                ...state,
                dataActive: [],
                error: action.error,
            };
        case types.FILTER_DONE_REQUEST:
            return {
                ...state,
            };
        case types.FILTER_DONE_SUCCESS:
            return {
                ...state,
                loading: false,
                tableInfoDone: {
                    ...state.tableInfoDone,
                    metadata: {
                        'allCount': action?.data?.data['hydra:totalItems'],
                        'filteredCount': action?.data?.data['hydra:totalItems'],
                        'lastPage': action?.data?.data['hydra:totalItems'] ? Math.ceil(action?.data?.data['hydra:totalItems'] / state.tableInfoDone.itemsPerPage) : 1,
                        'page': getCurrentPage(action?.data?.data['hydra:view']),
                        'itemsPerPage': state.tableInfoDone.itemsPerPage,
                    }
                },
                dataDone: action.data.data,
            };
        case types.FILTER_DONE_FAILURE:
            return {
                ...state,
                dataDone: [],
                error: action.error,
            };
        case types.GET_REQUEST:
            return {
                ...state,
            };
        case types.GET_SUCCESS:
            return {
                ...state,
                form: action.data.data,
            };
        case types.GET_FAILURE:
            return {
                ...state,
                form: {},
                error: action.error,
            };
        case types.GET_DATA_REQUEST:
            return {
                ...state,
            };
        case types.GET_DATA_SUCCESS:
            return {
                ...state,
                form: action.data.data,
            };
        case types.GET_DATA_FAILURE:
            return {
                ...state,
                form: {},
                error: action.error,
            };
        case types.GET_ENROLLMENT_HISTORY_REQUEST:
            return {
                ...state,
            };
        case types.GET_ENROLLMENT_HISTORY_SUCCESS:
            return {
                ...state,
                enrollmentHistory: action.data.data,
            };
        case types.GET_ENROLLMENT_HISTORY_FAILURE:
            return {
                ...state,
                enrollmentHistory: [],
                error: action.error,
            };
        case types.START_REQUEST:
            return {
                ...state,
            }
        case types.START_SUCCESS:
            return {
                ...state,
            }
        case types.START_FAILURE:
            return {
                ...state,
                error: action.error,
            }
        case types.FILL_STUDENT_FORM_REQUEST:
            return {
                ...state,
                loading: true,
            }
        case types.FILL_STUDENT_FORM_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case types.FILL_STUDENT_FORM_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            }
        case types.DELETE_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case types.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case types.GET_STUDENT_ONGOING_REQUEST:
            return {
                ...state,
            };
        case types.GET_STUDENT_ONGOING_SUCCESS:
            return {
                ...state,
                studentOngoing: action.data.data,
            };
        case types.GET_STUDENT_ONGOING_FAILURE:
            return {
                ...state,
                studentOngoing: {},
                error: action.error,
            };
        case types.GET_STUDENT_DONE_REQUEST:
            return {
                ...state,
            };
        case types.GET_STUDENT_DONE_SUCCESS:
            return {
                ...state,
                studentDone: action.data.data,
            };
        case types.GET_STUDENT_DONE_FAILURE:
            return {
                ...state,
                studentDone: {},
                error: action.error,
            };
        case types.GET_FORM_REQUEST:
            return {
                ...state,
            };
        case types.GET_FORM_SUCCESS:
            return {
                ...state,
                form: action.data.data,
            };
        case types.GET_FORM_FAILURE:
            return {
                ...state,
                form: {},
                error: action.error,
            };
        case types.GET_ACTUAL_UNIVERSITY_STUDIES_REQUEST:
            return {
                ...state,
            };
        case types.GET_ACTUAL_UNIVERSITY_STUDIES_SUCCESS:
            return {
                ...state,
                form: {
                    ...state.form,
                    actualUniversityStudies: action.data.data['hydra:member'],
                }
            };
        case types.GET_ACTUAL_UNIVERSITY_STUDIES_FAILURE:
            return {
                ...state,
                actualUniversityStudies: {},
                error: action.error,
            };
            case types.GET_STUDENT_CERTIFICATE_REQUEST:
                return {
                    ...state,
                };
            case types.GET_STUDENT_CERTIFICATE_SUCCESS:
                return {
                    ...state,
                    form: {
                        ...state.form,
                        studentCertificate: action.data.data['hydra:member'],
                    }
                };
            case types.GET_STUDENT_CERTIFICATE_FAILURE:
                return {
                    ...state,
                    studentCertificate: {},
                    error: action.error,
                };
        default:
            return {
                ...state
            }
    }
}