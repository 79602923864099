import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { memo } from "react";
import Select from "../../../Common/Select";
import TextField from "../../../Common/TextField";

const CONSENT_OPTION_LIST = [
    { value: "true", label: "Hozzájárul", booleanValue: true },
    { value: "false", label: "Nem járul hozzá", booleanValue: false },
];

const DonationConsentForm = memo(function DonationConsentForm(props) {
    const { classes, form, readOnly } = props;

    if (readOnly) {
        return (
            <TextField
                label="Név feltűntéséhez"
                name="consentToUseData"
                value={
                    CONSENT_OPTION_LIST.find((option) => {
                        return (
                            option.value === form?.consentToUseData ||
                            option.booleanValue === form?.consentToUseData
                        );
                    })?.label || "-"
                }
                format={{ xs: 12 }}
                disabled
            />
        );
    }

    return (
        <Paper
            className={classes.container}
            style={{ paddingTop: "8px" }}
            elevation={4}
        >
            <Grid container className={classes.textContainer}>
                <Grid item xs={12} container justify="center">
                    <div className={classes.title}>
                        Adományozási megállapodás
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        Adományozási megállapodás, amely létrejött
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        egyrészről a Mathias Corvinus Collegium Alapítvány 
                        (székhelye: 1016 Budapest, Somlói út 49-53., adószáma: 
                        18157918-2-41, képviselő neve: Szalai Zoltán) 
                        továbbiakban mint{" "}
                        <b>Adományozott</b>,
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        másrészről a Hallgató (továbbiakban mint
                        <b> Adományozó</b>) között, az alábbi feltételek
                        mellett:
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        A felek megegyeznek az alulírott napon az alábbiak
                        szerint:
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        1. Az Adományozó a kauciót nem igényli vissza, és 
                        mint adományt az Adományozott részére felajánlja.
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        2. Adomány értéke: 40.000,- Ft, azaz negyvenezer Forint.
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        3. Az Adományozott az adományt köszönettel fogadja.
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        4. A szerződő felek jelen megállapodást ügyleti 
                        akaratukkal mindenben egyezőnek találták és 
                        helybenhagyólag aláírták.
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        Az adományt a Mathias Corvinus Collegium Alapítvány cél szerint a 
                        közösségi terek felújítására fordíthatja.
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.text}>
                        Az Adományozó jelen szerződés aláírásával
                    </div>
                </Grid>
                <Select
                    name="consentToUseData"
                    value={form?.consentToUseData || ""}
                    optionList={CONSENT_OPTION_LIST}
                    functionToChild={props.changeForm}
                    formToChild={form}
                    formNameToChild="form"
                    format={{ xs: 12 }}
                    error={!!form?.errors?.consentToUseData}
                    helperText={form?.errors?.consentToUseData}
                />
                <Grid item xs={12}>
                    <div className={classes.text}>
                        hogy nevét, az adományozói státusz feltüntetésével az
                        Adományozott honlapján megjeleníthesse.
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
});

export default DonationConsentForm;
