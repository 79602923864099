import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Rotate90DegreesCcwIcon from "@material-ui/icons/Rotate90DegreesCcw";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";
import { Children, useCallback, useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import { connect } from "react-redux";
import { history } from "../../../../store";
import loadingActions from "../../../../store/loading/actions";
import documentsActions from "../../../../store/masterData/documents/actions";
import universitiesActions from "../../../../store/masterData/universities/actions";
import notificationActions from "../../../../store/notification/actions";
import enrollmentActions from "../../../../store/processes/newProcess/enrollment/actions";
import colors from "../../../../styles/colors";
import {bankAccountFormatter, isKep, options} from "../../../../utils/AppConst";
import { membershipStatusLabel } from "../../../../utils/cellRenderer/MemberShipStatusCellRenderer";
import Accordion from "../../../Common/Accordion";
import Address from "../../../Common/Address";
import CancelButton from "../../../Common/CancelButton";
import Checkbox from "../../../Common/Checkbox";
import DatePicker from "../../../Common/DatePicker";
import Dialog from "../../../Common/Dialog";
import Form from "../../../Common/Form";
import Loading from "../../../Common/Loading";
import PhoneInputField from "../../../Common/PhoneInputField";
import Radio from "../../../Common/Radio";
import Select from "../../../Common/Select";
import SubmitButton from "../../../Common/SubmitButton";
import TextField from "../../../Common/TextField";
import Title from "../../../Common/Title";
import FormCore from "./FormCore";
import { validationSchema } from "./utils";
import University from "./Universities";
import Autocomplete from "../../../Common/Autocomplete";
import Certificates from "./Certificates";

const useStyles = makeStyles((theme) => ({
    iframeDiv: {
        overflow: "hidden",
    },
    checkboxDiv: {
        cursor: "pointer",
    },
    text: {
        fontSize: "0.80rem",
    },
    typography: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "95%",
        textAlign: "justify",
    },
    title: {
        fontStyle: "italic",
        padding: "8px",
        fontSize: "17px",
    },
    page: {
        display: "flex",
        justifyContent: "center",
        marginBottom: "16px",
    },
    download: {
        backgroundColor: colors.new,
        "&:hover": {
            backgroundColor: colors.newHover,
        },
    },
}));

function Enrollment(props) {
    const { form, campuses, universities, loading, edit } = props;
    const id = props.match?.params?.id || null;
    const [availableDocuments, setAvailableDocuments] = useState([]);
    const [nationalities, setNationalities] = useState([]);
    const [fileToShowInDialog, setFileToShowInDialog] = useState(null);
    const [numPages, setNumPages] = useState(0);
    const [radioValue, setRadioValue] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));

    const radioOptions = [
        { value: "true", label: "Hozzájárulok" },
        { value: "false", label: "Nem járulok hozzá" },
    ];

    useEffect(() => {
        if (id && !edit) {
            props.loadingOn();
            axios
                .all([
                    props.getEnrollment(id),
                    props.getAvailableDocuments(id),
                    props.fetchUniversities({}),
                    props.getNationalities(),
                ])
                .then((data) => {
                    let cachedForm = data[0].data;
                    let formattedBankAccountNumber = "";
                    if (cachedForm?.bankAccountNumber) {
                        let bankAccountNumber = (
                            cachedForm?.bankAccountNumber || ""
                        ).replace(/[-\s]/g, "");
                        if (
                            bankAccountNumber.length >= 2 &&
                            isNaN(bankAccountNumber[0]) &&
                            isNaN(bankAccountNumber[1])
                        ) {
                            formattedBankAccountNumber =
                                bankAccountNumber.match(/.{1,4}/g)?.join(" ") || "";
                        } else if (
                            bankAccountNumber.length >= 2 &&
                            !isNaN(bankAccountNumber[0]) &&
                            !isNaN(bankAccountNumber[1])
                        ) {
                            formattedBankAccountNumber =
                                bankAccountNumber.match(/.{1,8}/g)?.join("-") || "";
                        }
                        props.changeForm(
                            {
                                ...cachedForm,
                                bankAccountNumber: formattedBankAccountNumber,
                                university: cachedForm?.university?.id,
                                acceptedDocuments: (
                                    cachedForm.acceptedDocuments || []
                                ).map((document) => document.id),
                            },
                            "form"
                        );
                    } else {
                        props.changeForm(
                            {
                                ...cachedForm,
                                university: cachedForm?.university?.id,
                                acceptedDocuments: (
                                    cachedForm.acceptedDocuments || []
                                ).map((document) => document.id),
                            },
                            "form"
                        );
                    }

                    setAvailableDocuments(data[1].data["hydra:member"]);
                    setNationalities(data[3].data);
                    props.loadingOff();
                });
            return props.defaultForm;
        } else if (!edit) {
            history.push("/404");
        }
    }, []);

    

    const handleClose = () => {
        setFileToShowInDialog(null);
        setRadioValue("");
    };

    const radioHandler = (e) => {
        setRadioValue(e.target.value);
    };

    const handleDownloadDocument = (document) => {
        props.addNotification("info", "A fájl letöltése folyamatban...");
        props
            .downloadDocument(document.id, document.name)
            .then(() => props.addNotification("success", "Sikeres letöltés!"))
            .catch((error) => {
                const errorMessage =
                    error.response.status === 404
                        ? "A fájl nem található"
                        : "Hiba a letöltés során";
                props.addNotification("error", errorMessage);
            });
    };

    const handlePreviewDocument = (id) => {
        setFileToShowInDialog(`${process.env.REACT_APP_API_URL}/enrollment-documents/${id}/preview`);
        form.acceptedDocuments?.find((x) => x === id)
            ? setRadioValue(
                  form.consentedDocuments?.find((x) => x === id)
                      ? "true"
                      : "false"
              )
            : "";
    };

    const handleAcceptDocument = (id, required) => {
        let acceptedDocuments = form.acceptedDocuments || [];
        let consentedDocuments = form.consentedDocuments || [];
        const document = availableDocuments.find((x) => x.id === id);

        if (
            ((required == 0 && radioValue === "true") || required == 1) &&
            !form.consentedDocuments?.find((x) => x === document?.id)
        ) {
            consentedDocuments.push(document?.id);
            consentedDocuments = [...new Set(consentedDocuments)];

            props.changeForm({ ...form, consentedDocuments }, "form");
        }

        acceptedDocuments.push(document?.id);
        acceptedDocuments = [...new Set(acceptedDocuments)];

        props.changeForm(
            {
                ...form,
                errors: {},
                acceptedDocuments,
            },
            "form"
        );

        setFileToShowInDialog(null);
        setRadioValue("");
    };

    const formProps = {
        form: form,
        name: "form",
        changeform: props.changeForm,
        defaultform: props.defaultForm,
        initialerrors: form["errors"] || {},
        initialvalues: form,
        validationschema: validationSchema,
        onsubmit: () => props.fillStudentForm(form, id, undefined, "/"),
        isformikform: true,
    };

    const bankAccountNumberFormatter = useCallback(
        (onChange) => (event) => {
            bankAccountFormatter(onChange, event);
        },
        []
    );

    if (loading) return <Loading />;

    // Jóváhagyás szerkesztés esetén:
    if (edit)
        return (
            <FormCore
                formProps={{
                    ...props.formProps,
                    validationschema: validationSchema,
                }}
                form={props.editForm}
                universities={universities}
                campuses={campuses}
                edit={true}
            />
        );

    const createPages = (n) => {
        let arr = [];
        for (let i = 0; i < n; i++) {
            arr.push(i);
        }

        return (
            <>
                {Children.toArray(
                    arr.map((index) => (
                        <Page
                            width={900}
                            pageNumber={index + 1}
                            className={classes.page}
                        />
                    ))
                )}
            </>
        );
    };

    //Beiratkozási űrlap:
    return (
        <Grid item {...formProps}>
            <Accordion title="Beiratkozási űrlap" expanded>
                <Form {...formProps}>
                    <Typography format={{ xs: 12 }} className={classes.title}>
                        {isKep() ? 'Amennyiben a feltöltött adatok hibásan szerepelnek, kérlek vedd fel a kapcsolatot a Kolozsvári Egyetemi Program munkatársaival a kep@mcc.hu e-mail címen.'
                            : 'Amennyiben a feltöltött adatok hibásan szerepelnek, kérlek vedd fel a kapcsolatot az Egyetemi Program munkatársaival az egyetemi_program@mcc.hu email címen.'}
                    </Typography>
                    <Title title="Típus" {...formProps} format={{ xs: 12 }}>
                        <TextField
                            name="type"
                            value={
                                form["type"] === "enrollment"
                                    ? "Beiratkozás"
                                    : "Kiiratkozás" || ""
                            }
                            format={{ xs: 12 }}
                            disabled
                        />
                    </Title>
                    <Title title="Név" {...formProps} format={{ xs: 12 }}>
                        <TextField
                            label="Vezetéknév"
                            name="lastName"
                            value={form.lastName || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled
                        />
                        <TextField
                            label="Keresztnév"
                            name="firstName"
                            value={form.firstName || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled
                        />
                    </Title>
                    <Title title="Képzés" {...formProps} format={{ xs: 12 }}>
                        <TextField
                            label="Képzési szint"
                            name="trainingLevel"
                            value={form.trainingLevel?.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled
                        />
                        <TextField
                            label="Képzési program"
                            name="trainingProgram"
                            value={form.trainingProgram?.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled
                        />
                        <TextField
                            label="Évfolyam"
                            name="collegeYear"
                            value={form.collegeYear?.name || ""}
                            format={{ xs: 12, md: 6 }}
                            disabled
                        />
                        <TextField
                            label="Státusz"
                            name="membershipStatus"
                            value={membershipStatusLabel(
                                form.membershipStatus || ""
                            )}
                            format={{ xs: 12, md: 6 }}
                            disabled
                        />
                        <Select
                            value={form.currentMembershipStatus || ""}
                            selectLabel="Aktuális státusz"
                            optionList={options.statusOptions}
                            name="currentMembershipStatus"
                            format={{ xs: 12, md: 6 }}
                        />
                        <Select
                            value={form.semesterStatus || ""}
                            selectLabel="Szemeszter státusz"
                            optionList={options.semesterStatusOptions}
                            name="semesterStatus"
                            format={{ xs: 12, md: 6 }}
                        />
                    </Title>
                    {'omId' in form && (
                        <Title
                        title="OM azonosító"
                        {...formProps}
                        format={{ xs: 12 }}
                        >
                            <TextField
                                name="omId"
                                value={form.omId || ""}
                                helperText={form.errors?.omId || "pl.: 71234567891"}
                                format={{ xs: 12 }}
                            />
                        </Title>
                    )}
                    {'taxId' in form && (
                        <Title
                            title="Adóazonosító jel"
                            {...formProps}
                            format={{ xs: 12 }}
                        >
                            <TextField
                                name="taxId"
                                value={form.taxId || ""}
                                format={{ xs: 12 }}
                                helperText={
                                    form.errors?.taxId || "pl.: 8412731018"
                                }
                            />
                        </Title>
                    )}
                    {form.currentMembershipStatus === "internal" ? (
                        <Title
                            title="Campus"
                            {...formProps}
                            format={{ xs: 12 }}
                        >
                            <TextField
                                label="Campus"
                                name="campus"
                                value={form.campus?.name || ""}
                                format={{ xs: 12, md: 6 }}
                                disabled
                            />
                            <TextField
                                label="Épület"
                                name="building"
                                value={form.building?.name || ""}
                                format={{ xs: 12, md: 6 }}
                                disabled
                            />
                            <TextField
                                label="Szint"
                                name="floor"
                                value={form.floor?.name || ""}
                                format={{ xs: 12, md: 6 }}
                                disabled
                            />
                            <TextField
                                label="Szoba"
                                name="room"
                                value={form.room?.name || ""}
                                format={{ xs: 12, md: 6 }}
                                disabled
                            />
                        </Title>
                    ) : (
                        <Title
                            title="Campus"
                            {...formProps}
                            format={{ xs: 12 }}
                        >
                            <TextField
                                name="campus"
                                value={form.campus?.name || ""}
                                format={{ xs: 12 }}
                                disabled
                            />
                        </Title>
                    )}
                    <Address
                        title="Állandó lakcím"
                        detailedAddress
                        {...formProps}
                        format={{ xs: 12 }}
                    />
                    {('motherFirstName' in form || 'motherLastName' in form) && (
                        <Title
                            title="Anyja neve"
                            {...formProps}
                            format={{ xs: 12 }}
                        >
                            {'motherLastName' in form && (
                                <TextField
                                    label="Vezetéknév"
                                    name="motherLastName"
                                    value={form.motherLastName || ""}
                                    format={{ xs: 12, md: 6 }}
                                />
                            )}
                            {'motherFirstName' in form && (
                                <TextField
                                    label="Keresztnév"
                                    name="motherFirstName"
                                    value={form.motherFirstName || ""}
                                    format={{ xs: 12, md: 6 }}
                                />
                            )}
                        </Title>
                    )}
                    {('birthPlace' in form || 'birthDate' in form) && (
                        <Title
                            title="Születési hely/idő"
                            {...formProps}
                            format={{ xs: 12 }}
                        >
                            {'birthPlace' in form && (
                                <TextField
                                    label="Születési hely"
                                    name="birthPlace"
                                    value={form.birthPlace || ""}
                                    format={{ xs: 12, md: 6 }}
                                />
                            )}
                            {'birthDate' in form && (
                                <DatePicker
                                    label="Születési idő"
                                    name="birthDate"
                                    value={form.birthDate || ""}
                                    format={{ xs: 12, md: 6 }}
                                />
                            )}
                        </Title>
                    )}
                    {'identificationNumberCnp' in form && (
                        <Title
                            title="Személyi szám (CNP)"
                            {...formProps}
                            format={{ xs: 12 }}
                        >
                            <TextField
                                name="identificationNumberCnp"
                                value={form.identificationNumberCnp || ""}
                                format={{ xs: 12 }}
                                helperText={
                                    form.errors?.identificationNumberCnp || "pl.: 8203114106118"
                                }
                                InputProps={ {InputProps: { maxLength: 13 } }} //FIXME: Nem működik
                            />
                        </Title>
                    )}
                    {'idCardNumber' in form && (
                        <Title title="SZIG szám" {...formProps} format={{ xs: 12 }}>
                            <TextField
                                name="idCardNumber"
                                value={form.idCardNumber || ""}
                                format={{ xs: 12 }}
                                helperText={
                                    form.errors?.idCardNumber || "pl.: 346243XX"
                                }
                            />
                        </Title>
                    )}
                    {'kepIdCardNumber' in form && (
                        <Title title="SZIG szám - KEP" {...formProps} format={{ xs: 12 }}>
                            <TextField
                                name="kepIdCardNumber"
                                value={form.kepIdCardNumber || ""}
                                format={{ xs: 12 }}
                                helperText={
                                    form.errors?.kepIdCardNumber || "pl.: RB219594" 
                                }
                            />
                        </Title>
                    )}
                    {'nationality' in form && (
                        <Title title="Állampolgárság" {...formProps} format={{ xs: 12 }}>
                            <Autocomplete
                                name='nationality'
                                value={form?.nationality || []} 
                                options={nationalities}
                                format={{ xs: 12 }}
                                multiple={true}
                                onChange={(_, v) => {
                                    props.changeForm(
                                        {
                                            ...form,
                                            nationality: v
                                        },
                                        "form"
                                    );
                                }}
                                error={form.errors?.nationality}
                                helperText={form.errors?.nationality}
                            />
                        </Title>
                    )}
                    {'taj' in form && (
                        <Title title="TAJ szám" {...formProps} format={{ xs: 12 }}>
                            <TextField
                                name="taj"
                                value={form.taj || ""}
                                format={{ xs: 12 }}
                                helperText={
                                    form.errors?.taj
                                }
                            />
                        </Title>
                    )}
                    {'bankName' in form && (
                        <Title
                            title="Pénzintézet neve"
                            {...formProps}
                            format={{ xs: 12 }}
                        >
                            <TextField
                                name="bankName"
                                value={form.bankName || ""}
                                format={{ xs: 12 }}
                            />
                        </Title>
                    )}
                    {'bankAccountNumber' in form && (
                        <Title
                            title="Bankszámlaszám"
                            {...formProps}
                            format={{ xs: 12 }}
                        >
                            <TextField
                                name="bankAccountNumber"
                                value={form.bankAccountNumber || ""}
                                format={{ xs: 12 }}
                                onChange={bankAccountNumberFormatter((value) => {
                                    formProps.changeform(
                                        { ...form, bankAccountNumber: value },
                                        "form"
                                    );
                                })}
                            />
                        </Title>
                    )}
                    <Title
                        title="Telefonszám"
                        {...formProps}
                        format={{ xs: 12 }}
                    >
                        <PhoneInputField
                            name="phoneNumber"
                            value={form.phoneNumber || ""}
                            format={{ xs: 12 }}
                            helperText={form.errors?.phoneNumber}
                        />
                    </Title>
                    <Title
                        title="E-mail cím"
                        {...formProps}
                        format={{ xs: 12 }}
                    >
                        <TextField
                            name="email"
                            value={form.email || ""}
                            format={{ xs: 12 }}
                            disabled
                        />
                    </Title>
                    <University format={{xs: 12}} />
                    <Certificates format={{xs: 12}} />
                    {availableDocuments.length > 0 && (
                        <Title
                            title="Dokumentumok"
                            {...formProps}
                            format={{ xs: 12 }}
                            error={form?.errors?.acceptedDocuments}
                        >
                            <Grid>
                                {Children.toArray(
                                    availableDocuments.map((document) => (
                                        <Dialog
                                            title={document.name}
                                            opener={
                                                <div
                                                    onClick={() =>
                                                        handlePreviewDocument(
                                                            document.id
                                                        )
                                                    }
                                                    className={
                                                        classes.checkboxDiv
                                                    }
                                                >
                                                    <Checkbox
                                                        option={{
                                                            name: `enrollment-document-${document.id}`,
                                                        }}
                                                        value={document.id}
                                                        checked={
                                                            form.acceptedDocuments.find(
                                                                (x) =>
                                                                    x ===
                                                                    document.id
                                                            )
                                                                ? true
                                                                : false
                                                        }
                                                        disabled
                                                    />
                                                    {document.name}
                                                </div>
                                            }
                                            action={
                                                <SubmitButton
                                                    onClick={() =>
                                                        handleAcceptDocument(
                                                            document.id,
                                                            document.required
                                                        )
                                                    }
                                                    disabled={
                                                        !fileToShowInDialog ||
                                                        isLoading ||
                                                        (document.required ===
                                                            "0" &&
                                                            radioValue === "")
                                                    }
                                                >
                                                    Elfogadom
                                                </SubmitButton>
                                            }
                                            download={
                                                <SubmitButton
                                                    onClick={() =>
                                                        handleDownloadDocument(
                                                            document
                                                        )
                                                    }
                                                    disabled={
                                                        !fileToShowInDialog
                                                    }
                                                    className={classes.download}
                                                >
                                                    Letöltés
                                                </SubmitButton>
                                            }
                                            handleClose={handleClose}
                                            contentStyle={{
                                                overflow: "auto",
                                            }}
                                            fullScreen
                                        >
                                            {fileToShowInDialog !== null ? (
                                                <div
                                                    className={
                                                        classes.iframeDiv
                                                    }
                                                >
                                                    <Document
                                                        file={
                                                            fileToShowInDialog
                                                        }
                                                        onLoadSuccess={(
                                                            pdf
                                                        ) => {
                                                            setNumPages(
                                                                pdf.numPages
                                                            );
                                                            setIsLoading(false);
                                                        }}
                                                        loading={
                                                            <Skeleton
                                                                variant="rect"
                                                                height={750}
                                                            />
                                                        }
                                                    >
                                                        {!matches && (
                                                            <Typography align="center">
                                                                <Rotate90DegreesCcwIcon />
                                                                <br />
                                                                <i>
                                                                    Amennyiben
                                                                    nem
                                                                    olvasható a
                                                                    tartalom,
                                                                    forgasd el
                                                                    készülékedet!
                                                                </i>
                                                            </Typography>
                                                        )}
                                                        {createPages(numPages)}
                                                    </Document>
                                                    {document.required == 0 &&
                                                        (document.consentText ? (
                                                            <>
                                                                <Typography
                                                                    align="left"
                                                                    variant="body1"
                                                                    gutterBottom
                                                                    className={
                                                                        classes.typography
                                                                    }
                                                                >
                                                                    {
                                                                        document.consentText
                                                                    }
                                                                </Typography>
                                                                <Radio
                                                                    options={
                                                                        radioOptions
                                                                    }
                                                                    handleChange={
                                                                        radioHandler
                                                                    }
                                                                    value={
                                                                        radioValue
                                                                    }
                                                                    label={null}
                                                                />
                                                            </>
                                                        ) : (
                                                            <Radio
                                                                options={
                                                                    radioOptions
                                                                }
                                                                handleChange={
                                                                    radioHandler
                                                                }
                                                                value={
                                                                    radioValue
                                                                }
                                                                label={null}
                                                            />
                                                        ))}
                                                    <Typography
                                                        align="left"
                                                        variant="body1"
                                                        gutterBottom
                                                        className={
                                                            classes.typography
                                                        }
                                                    >
                                                        {document.acceptText}
                                                    </Typography>
                                                </div>
                                            ) : (
                                                <Skeleton
                                                    variant="rect"
                                                    height={750}
                                                />
                                            )}
                                        </Dialog>
                                    ))
                                )}
                            </Grid>
                        </Title>
                    )}
                    <SubmitButton type="submit">Mentés</SubmitButton>
                    <CancelButton onClick={() => history.push("/")}>
                        Mégsem
                    </CancelButton>
                </Form>
            </Accordion>
        </Grid>
    );
}

function mapState(state) {
    const { form } = state.enrollment;
    const universities = state.universities.data;
    const loading = state.loading.loading;
    const file = state.documents.file;
    return { form, universities, loading, file };
}

const actionCreators = {
    changeForm: enrollmentActions.changeForm,
    defaultForm: enrollmentActions.defaultForm,
    getEnrollment: enrollmentActions.getForm,
    getAvailableDocuments: enrollmentActions.getAvailableDocuments,
    getNationalities: enrollmentActions.getNationalities,
    fetchUniversities: universitiesActions.filter,
    loadingOn: loadingActions.loadingOn,
    loadingOff: loadingActions.loadingOff,
    fillStudentForm: enrollmentActions.fillStudentForm,
    downloadDocument: documentsActions.download,
    addNotification: notificationActions.addNotification,
};

export default connect(mapState, actionCreators)(Enrollment);
